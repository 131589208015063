import { getService } from 'react-in-angularjs';

export const goTo = (path, replaceHistory) => {
  const utilService = getService('utilService');
  const $rootScope = getService('$rootScope');

  if (replaceHistory) {
    utilService.goToWithReplacingHistory(path);
  } else {
    utilService.goTo(path);
  }

  $rootScope.$apply();
};
