(function () {
    'use strict';

    angular.module('app.resetPassword')
        .controller('ResetPasswordController', ResetPasswordController);

    ResetPasswordController.$inject = [
        'AuthService'
    ];

    function ResetPasswordController(AuthService) {
        var vm = this;

        vm.email = '';

        vm.resetPassword = resetPassword;

        function resetPassword(form) {
            AuthService.resetPassword({email: vm.email}).then(function (res) {
                form['email'].$setValidity('notFound', res.status !== 401);
            });
        }
    }
})();
