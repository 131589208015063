// same as in css variables, if change also change variable

export const palette = {
  mainBlack: '#090B0E',
  white: '#ffffff',
  primary: {
    800: '#F17A09',
    700: '#F57D00',
    600: '#FB8D00',
    500: '#FF9900',
    400: '#FFA722',
    300: '#FFB84D',
    200: '#FFCC80',
  },
  info: {
    600: '#004BC3',
    500: '#0053CE',
    400: '#286CD9',
    300: '#5E86DF',
    200: '#91A9E8',
    100: '#BECAF1',
    50: '#F0F3FF',
  },
  success: {
    600: '#00A351',
    500: '#00B25B',
    400: '#1BBE72',
    300: '#58CA8C',
    200: '#8ED8AC',
    100: '#BBE8CD',
    50: '#E3F6EB',
  },
  warning: {
    600: '#FC9A13',
    500: '#FFA617',
    400: '#FFB332',
    300: '#FFC256',
    200: '#FFD387',
    100: '#FEE4B6',
    50: '#FFF5E2',
  },
  error: {
    600: '#DB2C2C',
    500: '#E9362D',
    400: '#E64948',
    300: '#F66464',
    200: '#FC9290',
    100: '#FFC9CE',
    50: '#FFE9ED',
  },
  natural: {
    700: '#5A5A65',
    600: '#6E6E79',
    500: '#9696A2',
    400: '#B6B6C2',
    300: '#DADAE6',
    200: '#E9E9F6',
    100: '#F2F2FE',
  },
  violet: {
    600: '#9860DB',
    500: '#A270DF',
    400: '#AD80E2',
    300: '#B790E6',
    200: '#C1A0E9',
    100: '#CBB0ED',
    50: '#D6BFF1',
  },
  background: {
    '50-dark': '#F2F2F7',
    '50-light': '#F8F8FA',
  },
  shades: {
    900: '#090B0E',
    50: '#FFFFFF',
  },
} as const;
