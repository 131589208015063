import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { Container, Pagination } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './NgzScholasticCustomerList.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { ScholasticCustomerPageTitle } from './ScholasticCustomerPageTitle/ScholasticCustomerPageTitle';
import { TitleRow } from './TitleRow/TitleRow';
import { scholasticCustomersListStore } from '../../stores/ScholasticCustomersListStore';
import { FilterRow } from './FilterRow/FilterRow';
import { CustomerRow } from './CustomerRow/CustomerRow';
import { EmptyListPlaceholder } from '../../../shared/components/EmptyListPlaceholder/EmptyListPlaceholder';
import { useCustomFilter } from '../../../shared/features/custom-filter';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';

const NgzScholasticCustomerList = observer(() => {
  const { initUserInfoFinished } = loggedInUserDataStore;
  const { customerList, getCustomerListInitData, filterMethod } =
    scholasticCustomersListStore;
  const {
    isShowPagination,
    isShowPlaceholder,
    emptyListText,
    isShowFilterRow,
    getPaginationProps,
  } = filterMethod;

  useCustomFilter('scholastic');

  useEffect(() => {
    if (initUserInfoFinished) {
      getCustomerListInitData();
    }
  }, [initUserInfoFinished]);

  const paginationProps = getPaginationProps();

  return (
    <SystemProviders>
      <Container>
        <ScholasticCustomerPageTitle />
        <div className={classes.scholasticTable}>
          <TitleRow />
          {isShowFilterRow && <FilterRow />}
          {customerList.map((userInfo) => (
            <CustomerRow userInfo={userInfo} key={userInfo.id} />
          ))}
        </div>
        {isShowPagination && <Pagination {...paginationProps} />}
        {isShowPlaceholder && <EmptyListPlaceholder text={emptyListText} />}
      </Container>
    </SystemProviders>
  );
});

angularize(
  NgzScholasticCustomerList,
  'ngzScholasticCustomerList',
  angular.module('app.scholastic'),
  {},
);

export { NgzScholasticCustomerList };
