const autoNgTemplateLoaderTemplate1 = require('./sign-in.template.html');

'use strict';

(function () {
    angular
        .module('app.signIn', [])
        .config(signInConfig);

    signInConfig.$inject = ['$routeProvider'];
    function signInConfig($routeProvider) {
        $routeProvider
            .when('/', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SignInController',
                controllerAs: 'vm',
                resolve: {
                    skipIfLoggedIn: ['AuthService', function (AuthService) {
                        return AuthService.skipIfLoggedIn();
                    }]
                }
            })
    }
})();
