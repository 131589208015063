import { clearUserInfo } from "../../modules/shared/utils/userInfoUtils";

(function () {
    'use strict';

    angular.module('app.signIn')
        .controller('SignInController', SignInController);

    SignInController.$inject = ['$rootScope', 'AuthService', 'AnalyticService', '$scope'];

    function SignInController($rootScope, AuthService, AnalyticService, $scope) {

        var vm = this;
        vm.mfaLogin = false;
        vm.spinner = { active: false };

        vm.signIn = signIn;
        vm.onSuccessSignIn = onSuccessSignIn;
        vm.pushUserDataToAnalytic = pushUserDataToAnalytic;
        vm.back = back;
        vm.clearUserInfo = clearUserInfo;

        $scope.$on('isLoading', function (event, isLoading) {
            setTimeout(() => {
                vm.spinner.active = isLoading;
            }, 0);
        });

        function signIn(form) {
            vm.spinner.active = true;
            AuthService.signIn(vm.email, vm.password, vm.mfaLogin)
              .then(function (data) {
                  form['email'].$setValidity('notFound', !(data.status === 401 && data.data.message === 'User not found'));
                  if (data.status === 211) {
                      vm.mfaLogin = true;
                      vm.password = '';
                      form.$setPristine();
                  } else {
                      vm.onSuccessSignIn(data.data);
                  }
              })
              .finally(() => vm.spinner.active = false);
        }

        function onSuccessSignIn(userData) {
            vm.user = userData;
            vm.pushUserDataToAnalytic();
        }

        function pushUserDataToAnalytic() {
            AnalyticService.pushData({
                'event': 'setUserId',
                'user_id': JSON.stringify(vm.user.id)
            }, {
                'event': 'login-complete',
                'user-id': vm.user.id,
                'user-type': vm.user.role
            });
            AnalyticService.pushProperty('addUserProperties', 'd-v1-up2', 'user_role', vm.user.role);
        }

        function back() {
            vm.mfaLogin = false;
            vm.clearUserInfo()
        }
    }
})();
