'use strict';

angular.module('app').controller('SuperDashboardController', SuperDashboardController);
SuperDashboardController.$inject = [
    '$scope',
    '$window',
    '$location',
    'ChartService',
    'EntityService',
    'StatsService',
    'ScenariosService',
    'constants'
];

function SuperDashboardController($scope, $window, $location, ChartService, EntityService, StatsService, ScenariosService, constants) {
    var vm = this;
    vm.periodTabName = 'day';
    vm.selectedEntityTab = {id: null, business_name: 'LifeBase Solutions'};
    vm.availableEntities = [];
    vm.amountOfUsersBilledData = [];
    vm.chartLimit = 5;
    vm.summaryInfo = {};
    vm.spinner = { usersBilled: true, summaryInfo: true };
    vm.amountOfUsersBilledChart = null;

    vm.getAmountOfUsersBilledData = getAmountOfUsersBilledData;
    vm.getSuperSummaryInfo = getSuperSummaryInfo;
    vm.getAllEntities = getAllEntities;
    vm.importTemplate = importTemplate;
    $scope.exportScenarios = exportScenarios;
    vm.newPeriodTab = newPeriodTab;
    vm.incrementValue = incrementValue;
    vm.decrementValue = decrementValue;
    vm.processAmountOfUsersBilled = processAmountOfUsersBilled;
    vm.createAmountOfUsersBilledChart = createAmountOfUsersBilledChart;
    vm.goTo = goTo;

    getAllEntities();
    getAmountOfUsersBilledData();
    getSuperSummaryInfo();

    function getAmountOfUsersBilledData() {
        vm.spinner.usersBilled = true;
        StatsService.getAmountOfUsersBilledData({definition: vm.periodTabName ? vm.periodTabName : null}).then(function (result) {
            if (result.status === 200) {
                vm.amountOfUsersBilledData = result.data.data;

                var size = vm.amountOfUsersBilledData.length;
                if (vm.chartLimit > size) vm.chartLimit = size;
                vm.activeIndex = size - 1;
                vm.firstIndex = size - vm.chartLimit;

                vm.createAmountOfUsersBilledChart();
            }
        });
    }

    function getSuperSummaryInfo(entity) {
        StatsService.getSuperSummaryInfo(entity).then(function (result) {
            if (result.status === 200) {
                vm.summaryInfo = result.data;
                vm.spinner.summaryInfo = false;
            }
        });
    }

    function getAllEntities() {
        EntityService.getEntitiesBasics().then(function (result) {
            if (result.status === 200) {
                vm.availableEntities = angular.copy(result.data.entities);
                vm.availableEntities.unshift({id: null, business_name: 'LifeBase Solutions'});
            }
        });
    }

    function importTemplate(template) {
        ScenariosService.import().then(function (result) {
            if (result.status === 200) {
                $window.location.href = constants.url + 'api/public/scenarios/' + template + '?token=' + result.data.token;
            }
        });
    }

    function exportScenarios(event) {
        var file = event.target.files[0];
        var reader = new FileReader();

        reader.onload = function (e) {
            ScenariosService.export(e);
        };

        reader.readAsBinaryString(file);
    }

    function newPeriodTab(value) {
        vm.periodTabName = value;
        vm.getAmountOfUsersBilledData();
    }

    function incrementValue() {
        if (vm.activeIndex !== vm.amountOfUsersBilledData.length - 1) {
            vm.activeIndex++;
            if (vm.activeIndex === vm.firstIndex + vm.chartLimit - 1 && vm.activeIndex !== vm.amountOfUsersBilledData.length - 1) {
                vm.firstIndex++;
            }
        }

        vm.createAmountOfUsersBilledChart();
    }

    function decrementValue() {
        if (vm.activeIndex !== 0) {
            vm.activeIndex--;
            if (vm.activeIndex === vm.firstIndex && vm.activeIndex !== 0) {
                vm.firstIndex--;
            }
        }

        vm.createAmountOfUsersBilledChart();
    }

    function processAmountOfUsersBilled() {
        var values = [];
        vm.dateValues = [];

        for (var i = vm.firstIndex; i < vm.firstIndex + vm.chartLimit; i++) {
            values.push(vm.amountOfUsersBilledData[i]);
            vm.dateValues.push({
                index: i,
                date: vm.amountOfUsersBilledData[i].range
            });
        }

        return values;
    }

    function createAmountOfUsersBilledChart() {
        var values = vm.processAmountOfUsersBilled();

        vm.amountOfUsersBilledChart = ChartService.createLineFilledChart(
            'amountOfUsersBilled',
            values,
            vm.amountOfUsersBilledData[vm.activeIndex],
            vm.amountOfUsersBilledChart,
        );
        vm.spinner.usersBilled = false;
    }

    function goTo(path) {
        $location.path(path);
    }
}
