import { Flags, ValueOfKey } from '../../utils-types';

export const PAYMENT_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  INCOMPLETE: 'INCOMPLETE',
  INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
  PAYMENT_ISSUE: 'PAYMENT_ISSUE',
} as const;

export type PaymentStatus = ValueOfKey<typeof PAYMENT_STATUS>;
export type PaymentStatusFlags = Flags<PaymentStatus>;
