'use strict';

angular.module('app').controller('EntitySetupStep4Controller', EntitySetupStep4Controller);

EntitySetupStep4Controller.$inject = ['$scope', 'GeneralService', 'EntityService', 'utilService', '$location'];

function EntitySetupStep4Controller($scope, GeneralService, EntityService, utilService, $location) {
    var vm = this;
    vm.spinner = $scope.spinner;
    vm.createEntity = $scope.createEntity;
    vm.entityCredentials = {};

    vm.init = init;
    vm.onResetLogin = onResetLogin;
    vm.checkPassword = checkPassword;
    vm.onSubmit = onSubmit;

    init();

    function init() {
        const email = vm.createEntity?.userEmail;
        if (email) {
            vm.entityCredentials.loginID = email.slice(0, email.indexOf('@'));
        }
    }

    function checkPassword(form, password, repeatedPassword) {
        GeneralService.checkPassword(form, password, repeatedPassword, vm.entityCredentials);
    }
    
    function onResetLogin() {
        vm.entityCredentials.loginID = '';
    }

    function onSubmit() {
        vm.spinner.active = true;
        EntityService.setupCredentials(vm.createEntity.entityID, vm.entityCredentials)
            .then(res => {
                if (res.status === 200) {
                    utilService.goToWithReplacingHistory('entity-profile/' + vm.createEntity.entityID);
                    $location.search({});
                }
            })
            .finally(() => vm.spinner.active = false);
    }
}
