import React from 'react';

import { Paper } from '@mui/material';
import { observer } from 'mobx-react';
import { useWindowSize } from 'react-use';

import classes from './CoachTable.module.scss';
import { FiltersRow } from './FiltersRow/FiltersRow';
import { CoachRow } from './CoachRow/CoachRow';
import {
  EmptyList,
  LbPagination,
} from '../../../../../../shared/components/ui-components';
import { coachStore } from '../../../../../stores/customerSelfSetupStores/coachStore';
import { TitlesRow } from './TitlesRow/TitlesRow';

const CoachTable = observer(() => {
  const { coachList, selectedCoach, setSelectedCoach, filterMethods } = coachStore;

  const { filterData, pagesCount, elementsPerPage, totalElements } = filterMethods;
  const { page, onChange } = filterMethods.getPaginationProps();

  const { coachType } = filterData;

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const renderTableHeaderRow = () => {
    switch (coachType) {
      case 'OFFLINE':
        return <FiltersRow isMobile={isMobile} />;
      case 'REMOTE':
        return <TitlesRow isMobile={isMobile} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className={classes.table}>
        {renderTableHeaderRow()}
        {coachList.map((coach) => (
          <CoachRow
            key={coach.coachId}
            coach={coach}
            selectedCoachId={selectedCoach?.coachId}
            onClick={setSelectedCoach}
            isMobile={isMobile}
          />
        ))}
      </div>
      <LbPagination
        elementsPerPage={elementsPerPage}
        count={pagesCount}
        variant="outBox"
        totalElements={totalElements}
        page={page}
        onChange={onChange}
      />
      <Paper variant="roundedBox">
        <EmptyList
          show={!totalElements}
          description={
            coachType
              ? 'Coach list is empty'
              : 'To find coach, please select collaboration format'
          }
        />
      </Paper>
    </>
  );
});

export { CoachTable };
