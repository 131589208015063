import React from 'react';

import { observer } from 'mobx-react';

import classes from './CoachCard.module.scss';
import { CoachNameBlock } from './CoachNameBlock/CoachNameBlock';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';
import { CollaborationType } from '../../../../shared/interfaces/coach/collaborationType';
import { InfoBox } from '../../../../shared/components/ui-components';

type Coach = {
  coachId: number;
  entityId: number;
  coachName: string;
  coachAvatar: string | null;
  entityName: string;
  distance?: number;
};

type CoachCardProps = {
  coachInfo: Coach;
  coachType: CollaborationType;
};

const CoachCard = observer(({ coachInfo, coachType }: CoachCardProps) => {
  return (
    <InfoBox title="Your coach">
      <div className={classes.container}>
        <CoachNameBlock
          avatarUrl={coachInfo.coachAvatar}
          name={coachInfo.coachName}
          distance={coachInfo.distance}
        />
        <TitleWithDescription
          className={classes.infoBlock}
          title="Business Name"
          description={coachInfo.entityName}
          titleFont="body-14-bold"
          descriptionColor="main-black"
        />
        {coachType === 'REMOTE' && (
          <TitleWithDescription
            className={classes.infoBlock}
            title="Collaboration"
            description="To work with a coach remotely"
            titleFont="body-14-bold"
            descriptionColor="main-black"
          />
        )}
      </div>
    </InfoBox>
  );
});

export { CoachCard };
