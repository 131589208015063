import { debounce } from 'lodash';
import { useEffect } from 'react';
import { FieldValues, Path, UseFormWatch } from 'react-hook-form';

export const useAutoSubmitForm = <TFieldValues extends FieldValues>(
  handleFormSubmit: () => void,
  watch: UseFormWatch<TFieldValues>,
  debouncedFields: Path<TFieldValues>[] = [],
) => {
  useEffect(() => {
    const debouncedSubmit = debounce(handleFormSubmit, 800);

    const subscription = watch((value, { name }) => {
      const isDebouncedField = debouncedFields.includes(name!);

      if (isDebouncedField) {
        debouncedSubmit();
      } else {
        handleFormSubmit();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
};
