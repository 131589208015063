import React from 'react';

import { useToggle } from 'react-use';
import { Button } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import classes from './EntityInbodyKeyTable.module.scss';
import { AddInBodyKeyModal } from './AddInBodyKeyModal/AddInBodyKeyModal';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { InbodyKeyRow } from './InbodyKeyRow/InbodyKeyRow';
import { EmptyList, InfoBox } from '../../../../../shared/components/ui-components';
import { UseEntityInbodyKeyReturn } from '../../../../hooks/useEntityInbodyKey';

type EntityInbodyKeyTableProps = UseEntityInbodyKeyReturn;

const EntityInbodyKeyTable = ({
  addInbodyKey,
  deleteInbodyKey,
  inbodyKeys,
}: EntityInbodyKeyTableProps) => {
  const [isShowAddInBodyKeyModal, toggleShowAddInBodyKeyModal] = useToggle(false);

  const AddInBodyKeyButton = (
    <Button
      variant="outlined"
      size="small"
      startIcon={<AddRoundedIcon />}
      onClick={toggleShowAddInBodyKeyModal}
    >
      Add InBody Key
    </Button>
  );

  return (
    <>
      <InfoBox
        title="Entity InBody Key"
        variant="innerBox"
        icon={AddInBodyKeyButton}
        customMargin
        customContentPadding
      >
        <div className={classes.inbodyKeyTable}>
          <HeaderRow />
          {inbodyKeys.map((inbodyKeyInfo) => (
            <InbodyKeyRow
              key={inbodyKeyInfo.id}
              inbodyKeyInfo={inbodyKeyInfo}
              onDelete={deleteInbodyKey}
            />
          ))}
          <EmptyList show={!inbodyKeys.length} description="There no InBody Keys" />
        </div>
      </InfoBox>
      <AddInBodyKeyModal
        open={isShowAddInBodyKeyModal}
        onClose={toggleShowAddInBodyKeyModal}
        onSubmit={addInbodyKey}
      />
    </>
  );
};

export { EntityInbodyKeyTable };
