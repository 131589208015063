(function () {
    'use strict';

    angular.module('app.addRecipe').controller('AddRecipeController', AddRecipeController);

    AddRecipeController.$inject = [
        '$scope',
        '$rootScope',
        '$location',
        '$routeParams',
        'RecipeService',
        'FoodService',
        'ModalService',
        'utilService'
    ];

    function AddRecipeController($scope, $rootScope, $location, $routeParams, RecipeService, FoodService, ModalService, utilService) {
        var vm = this;
        vm.recipeImage = null;
        vm.isSavedAsFood = false;
        vm.spinner = {active: false};
        vm.loggedInUser = utilService.getLoggedInUserData();

        vm.saveRecipeAsFood = saveRecipeAsFood;
        vm.initFoodDetails = initFoodDetails;
        vm.changeRecipeImage = changeRecipeImage;
        vm.roundMeal = roundMeal;
        vm.calculateMacros = calculateMacros;
        vm.saveRecipe = saveRecipe;
        vm.deleteCurrentRecipe = deleteCurrentRecipe;
        vm.showSelectIngredientDialog = showSelectIngredientDialog;
        vm.showEditIngredientDialog = showEditIngredientDialog;
        vm.removeIngredient = removeIngredient;
        vm.addIngredient = addIngredient;
        vm.editIngredient = editIngredient;
        vm.findIngredientById = findIngredientById;
        vm.prepareIngredientsData = prepareIngredientsData;
        vm.goToRecipeList = goToRecipeList;
        vm.goTo = goTo;


        function saveRecipeAsFood() {
            ModalService.saveRecipeAsFood(vm.newRecipe, vm.roundMeal, function () {
                vm.isSavedAsFood = true;
            });
        }

        function initFoodDetails() {
            var emptyRecipe = {
                servingSize: 1,
                ingredients: [],
                macrosPerServing: {carbs: 0, fats: 0, proteins: 0, sugar: 0, fiber: 0, veggies: 0, sodium: 0, calorie: 0},
                totalMacros: {carbs: 0, fats: 0, proteins: 0, sugar: 0, fiber: 0, veggies: 0, sodium: 0, calorie: 0}
            };

            vm.servingMeasurements = [];
            vm.newRecipe = emptyRecipe;

            vm.spinner.active = true;
            FoodService.getAllMeasurements().then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    vm.servingMeasurements = res.data.measurements;
                    var recipeId = vm.loggedInUser.role !== 'CUSTOMER' ? $routeParams.id : $scope.recipeId;
                    if (recipeId) {
                        vm.spinner.active = true;
                        RecipeService.getRecipeDetails(recipeId).then(function (result) {
                            vm.spinner.active = false;
                            if (result.status === 200) {
                                vm.newRecipe = result.data;
                                for (var i in vm.newRecipe.ingredients) {
                                    var item = vm.newRecipe.ingredients[i];
                                    item.carbs *= item.amount;
                                    item.fats *= item.amount;
                                    item.proteins *= item.amount;
                                    item.fiber *= item.amount;
                                    item.veggies *= item.amount;
                                    item.sugar *= item.amount;
                                    item.sodium *= item.amount;
                                    item.calorie *= item.amount;
                                }
                                vm.calculateMacros();
                            }
                        })
                    }
                }
            });
        }

        function changeRecipeImage() {
            if (vm.recipeImage) {
                vm.newRecipe.image = URL.createObjectURL(vm.recipeImage);
            }
        }

        function roundMeal(value) {
            var processedValue = parseFloat((value % 1).toFixed(2));
            if (processedValue >= 0.05) {
                if (processedValue >= 0.95) {
                    return Math.ceil(value);
                }
                return value.toFixed(1);
            } else {
                return Math.floor(value);
            }
        }

        function calculateMacros() {
            for (var property in vm.newRecipe.macrosPerServing) {
                var propSum = utilService.sum(vm.newRecipe.ingredients, property);
                vm.newRecipe.totalMacros[property] = parseFloat(propSum.toFixed(1));
                vm.newRecipe.macrosPerServing[property] = parseFloat((propSum / vm.newRecipe.servingSize).toFixed(1));
            }
        }

        function deleteCurrentRecipe() {
            vm.spinner.active = true;
            RecipeService.deleteRecipe(vm.newRecipe.id).then(function (result) {
                vm.spinner.active = false;
                if (result.status === 200) {
                    vm.loggedInUser.role !== 'CUSTOMER' ?
                        vm.goTo('/recipes') :
                        vm.goToRecipeList();
                } else {
                    ModalService.alert(result.data.message, 'Error')
                }
            });
        }

        function prepareIngredientsData() {
            var ingredientsObj = {general: [], network: []};
            vm.newRecipe.ingredients.forEach(function (e) {
                var veggiesPerUnit = e.veggies > 0 ? (e.veggies / e.amount) : e.veggies;
                var ingredient = {
                    amount: e.amount,
                    amountUnit: e.amountUnit,
                    veggies: veggiesPerUnit
                };
                e.foodId ? ingredient.foodId = e.foodId : ingredient.id = e.id;
                (e.my_net_diary_bean_id ? ingredientsObj.network : ingredientsObj.general).push(ingredient)
            });
            return ingredientsObj;
        }

        function saveRecipe() {
            var formData = new FormData();
            formData.append('name', vm.newRecipe.name);
            formData.append('description', vm.newRecipe.description || '');
            formData.append('servingSize', vm.newRecipe.servingSize);
            formData.append('servingMeasurement', vm.newRecipe.servingMeasurement);
            formData.append('amount', vm.newRecipe.amount);
            formData.append('ingredients', JSON.stringify(vm.prepareIngredientsData()));
            if (vm.recipeImage) formData.append('file', vm.recipeImage, vm.recipeImage.$ngfName);

            vm.spinner.active = true;
            (vm.newRecipe.id ?
                RecipeService.updateRecipe(vm.newRecipe.id, formData) :
                RecipeService.createRecipe(formData))
                .then(function (result) {
                    vm.spinner.active = false;
                    if (result.status === 200) {
                        vm.loggedInUser.role !== 'CUSTOMER' ?
                            vm.goTo('/recipes') :
                            vm.goToRecipeList();
                    }
                })
        }

        function showSelectIngredientDialog() {
            ModalService.selectIngredient(vm.addIngredient, vm.roundMeal);
        }

        function showEditIngredientDialog(ingredient) {
            ModalService.ingredientDetails(angular.copy(ingredient), vm.roundMeal, vm.editIngredient, vm.removeIngredient);
        }

        function findIngredientById(item) {
            // new ingredient - search by food id (from db or nutrientIX)
            // edited ingredient - search by ingredient id
            return vm.newRecipe.ingredients.findIndex(function (i) {
                return (item.foodId && i.foodId === item.foodId) || (item.id && i.id === item.id);
            });
        }

        function removeIngredient(item) {
            vm.newRecipe.ingredients.splice(vm.findIngredientById(item), 1);
            vm.calculateMacros();
        }

        function addIngredient(item) {
            var index = vm.newRecipe.ingredients.findIndex(function (i) {
                return i.name.toLowerCase() === item.name.toLowerCase();
            });

            if (index > -1) {
                ModalService.alert('This ingredient is already added to recipe.', 'Warning');
            } else {
                item.foodId = item.my_net_diary_bean_id ? item.my_net_diary_bean_id : item.id;
                delete item.id;
                vm.newRecipe.ingredients.push(item);
                vm.calculateMacros();
            }
        }

        function editIngredient(item) {
            var index = vm.findIngredientById(item);
            if (index < 0) {
                ModalService.alert('The ingredient was not found.', 'Warning');
            } else {
                vm.newRecipe.ingredients[index] = item;
                vm.calculateMacros();
            }
        }

        function goToRecipeList() {
            $rootScope.$broadcast('goToRecipeList')
        }

        function goTo(path) {
            $location.path(path);
        }
    }
})();
