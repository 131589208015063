(function () {
    'use strict';

    angular.module('app.supplementProfile').controller('SupplementProfileController', SupplementProfileController);

    SupplementProfileController.$inject = [
        '$location',
        '$routeParams',
        'utilService',
        'ModalService',
        'CoachService',
        'SupplementService',
        'PaymentService',
        'GeneralService',
        'TermsAndConditionService',
        'constants',
        'StripeService'
    ];

    function SupplementProfileController($location, $routeParams, utilService, ModalService, CoachService,
                                         SupplementService, PaymentService, GeneralService, TermsAndConditionService,
                                         constants, StripeService) {
        var vm = this;
        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.spinner = {active: false};
        vm.supplementUser = {
            country: 'USA',
            coach: {}
        };
        vm.profileTabName = 'userDetails';
        vm.isChangeSubscriptionState = false;
        vm.isReadTerms = false;
        vm.checkingReadTerms = false;
        vm.resendingTerms = false;
        vm.currentSubscriptionType = {name: 'supplement', value: 'SUPPLEMENT'};
        vm.entityId = null;
        vm.customerId = $routeParams.id;
        vm.paymentFlowType = {};
        vm.isStripeUsed = false;

        vm.init = init;
        vm.getCurrentUser = getCurrentUser;
        vm.updateUser = updateUser;
        vm.getSubscriptionsList = getSubscriptionsList;
        vm.calculateExpirationPeriod = calculateExpirationPeriod;
        vm.getMaxValue = getMaxValue;
        vm.selectSubscription = selectSubscription;
        vm.showDialog = showDialog;
        vm.checkRenewalSubscriptions = checkRenewalSubscriptions;
        vm.formatExpireDate = formatExpireDate;
        vm.cancelRenewal = cancelRenewal;
        vm.getAvailableSubscriptions = getAvailableSubscriptions;
        vm.pay = pay;
        vm.payNewFlow = payNewFlow;
        vm.successAddSubscriptionHandler = successAddSubscriptionHandler;
        vm.payOldFlow = payOldFlow;
        vm.createManualTransaction = createManualTransaction;
        vm.showUpdateCardDialog = showUpdateCardDialog;
        vm.showNewUpdateCardDialog = showNewUpdateCardDialog;
        vm.showOldUpdateCardDialog = showOldUpdateCardDialog;
        vm.paySubscriptionByUser = paySubscriptionByUser;
        vm.updateUserPaymentSource = updateUserPaymentSource;
        vm.getStripePublicKey = getStripePublicKey;
        vm.getEntityInfo = getEntityInfo;
        vm.showDeleteCoachDialog = showDeleteCoachDialog;
        vm.deleteCurrentUser = deleteCurrentUser;
        vm.checkIsReadTerms = checkIsReadTerms;
        vm.resendTerms = resendTerms;
        vm.goBack = goBack;
        vm.checkSubscriptions = checkSubscriptions;

        vm.init();

        function init() {
            vm.getCurrentUser();
            vm.getStripePublicKey();
            vm.getSubscriptionsList();
        }

        function checkSubscriptions() {
            return vm.gymDetails.subscriptions.filter(function (value) {
                return value.active && value.subscriptionType === vm.currentSubscriptionType.value;
            }).length > 0;
        }

        function goBack() {
            utilService.goBackWithParam('/supplement-customers-list', constants.pages.supplementUserList);
        }

        function checkIsReadTerms() {
            vm.checkingReadTerms = true;
            TermsAndConditionService.checkIfTermsAndConditionAreReadBySupplementUser(vm.supplementUser.id)
                .then(function (result) {
                    vm.checkingReadTerms = false;
                    if (result.status === 200) {
                        vm.isReadTerms = result.data.read;

                        if (!vm.isReadTerms && $location.path().indexOf('/supplement-profile') > -1) {
                            setTimeout(function () {
                                vm.checkIsReadTerms()
                            }, 5000);
                        }
                    }
                })
        }

        function resendTerms() {
            vm.resendingTerms = true;
            TermsAndConditionService.resendTermsAndConditionAreReadToSupplementUser(vm.supplementUser.id)
                .then(function (result) {
                    vm.resendingTerms = false;
                    if (result.status === 200) {
                        var sent = result.data.sent;
                        var message = sent.sms && sent.email ?
                            'You successfully sent email and sms with Terms & Conditions agreement' :
                            'You successfully sent ' + (!sent.sms ? 'email' : 'sms') + ' with Terms & Conditions agreement';
                        ModalService.alert(message, 'Success');
                    }
                })
        }

        function showDeleteCoachDialog() {
            ModalService.confirm('Are you sure you want to delete an user?',
                vm.deleteCurrentUser, $routeParams.id, null, 'Cancel', 'Delete')
        }

        function deleteCurrentUser(id) {
            vm.spinner.active = true;
            GeneralService.deleteUserBySuper(id).then(function (response) {
                vm.spinner.active = false;
                if (response.status === 200) {
                    $location.path('/supplement-customers-list');
                }
            })
        }

        function getStripePublicKey() {
            GeneralService.getStripePublicKey().then(function (res) {
                if (res.status === 200 && res.data.key) {
                    vm.handler = StripeCheckout.configure({
                        key: res.data.key,
                        image: 'https://content.lifebase.solutions/static-content/logo.png',
                        locale: 'auto',
                        token: vm.paySubscriptionByUser
                    });

                    vm.updatePaymentConf = StripeCheckout.configure({
                        key: res.data.key,
                        image: 'https://content.lifebase.solutions/static-content/logo.png',
                        locale: 'auto',
                        panelLabel: 'Update',
                        source: vm.updateUserPaymentSource
                    });
                }
            });

        }

        function updateUserPaymentSource(src) {
            PaymentService.updateUserPaymentSource(vm.supplementUser.id, {src: src.id});
        }

        function paySubscriptionByUser(token) {
            vm.spinner.active = true;

            PaymentService.paySubscriptionByUser($routeParams.id, {
                setup_price: vm.setUpEntityPrice,
                init_invoice_price: vm.initInvoiceEntityPrice,
                renew_fee_price: vm.renewFeeEntityPrice,
                token: token.id,
                subscription: vm.selectedSubscription.id
            }).then(function (res) {
                vm.spinner.active = false;

                switch (res.status) {
                    case 200:
                        vm.isChangeSubscriptionState = false;
                        vm.subscriptionLoading = true;
                        setTimeout(function () {
                            vm.getSubscriptionsList();
                        }, 5000);
                        break;
                    case 402:
                        ModalService.confirm('Error Message', vm.pay, null, res.data.message, null, 'Enter card details');
                        break;
                }
            });
        }

        function getCurrentUser() {
            SupplementService.getUserById($routeParams.id).then(function (res) {
                if (res.status === 200) {
                    // fullName deprecated LMP-2282 remove after release LMP-2311
                    delete res.data.fullName;

                    vm.supplementUser = res.data;
                    vm.selectedDOB = moment(vm.supplementUser.dob).format('MM.DD.YYYY');
                    vm.checkIsReadTerms();
                    vm.getAvailableSubscriptions();
                }
            });
        }

        function updateUser() {
            vm.supplementUserForResuest = JSON.parse(JSON.stringify(vm.supplementUser));
            vm.supplementUserForResuest.dob = moment(vm.supplementUser.dob).format('MM/DD/YYYY');
            vm.supplementUserForResuest.gender = vm.supplementUser.gender.toLowerCase();

            SupplementService.editSupplementUser($routeParams.id, vm.supplementUserForResuest);
        }

        function getSubscriptionsList() {
            PaymentService.getUserPaymentTransactions($routeParams.id).then(function (res) {
                vm.subscriptionLoading = false;
                if (res.status === 200) {
                    vm.supplementUserSubscriptions = res.data.subscriptions;
                }
            });
        }

        function calculateExpirationPeriod(date) {
            return PaymentService.calculateExpirationPeriod(date);
        }

        function getMaxValue(minFee, fee, percent) {
            var feeWithPercent = fee * percent / 100;

            if (minFee > fee - feeWithPercent) return Math.ceil(minFee);
            else return Math.ceil(fee - feeWithPercent);
        }

        function selectSubscription(subscription, index) {
            vm.selectedSubscription = subscription;
            vm.selectedSubscriptionIndex = index;

            for (var i = 0; i < vm.gymDetails.subscriptions.length; i++) {
                var currentSubscription = vm.gymDetails.subscriptions[i];
                if (i !== index) {
                    currentSubscription.setUpEntityPrice = vm.initialSubscriptionsDetails[i].setUpEntityPrice;
                    currentSubscription.initInvoiceEntityPrice = vm.initialSubscriptionsDetails[i].initInvoiceEntityPrice;
                    currentSubscription.renewFeeEntityPrice = vm.initialSubscriptionsDetails[i].renewFeeEntityPrice;
                }
            }
        }

        function showDialog(subscription_name, description) {
            ModalService.getSubscriptionInfo({title: subscription_name, info: description});
        }

        function checkRenewalSubscriptions() {
            if (vm.supplementUserSubscriptions) {
                for (var i = 0; i < vm.supplementUserSubscriptions.length; i++) {
                    var subscr = vm.supplementUserSubscriptions[i];
                    if (subscr.renewal_active && subscr.is_finished) {
                        return subscr;
                    }
                }
            }
            return -1;
        }

        function formatExpireDate(date) {
            return utilService.formatExpireDate(date);
        }

        function cancelRenewal() {
            vm.canceledPlan = vm.checkRenewalSubscriptions();

            if (vm.canceledPlan !== -1) {
                PaymentService.cancelRenewalSubscription(vm.canceledPlan.id).then(function (res) {
                    if (res.status === 200) {
                        ModalService.canceledEntityPlanInfoDialog({
                            name: vm.canceledPlan.subscription_name,
                            validTo: vm.canceledPlan.valid_to
                        }, vm.getSubscriptionsList);
                    }
                });
            }
        }

        function getAvailableSubscriptions() {
            CoachService.getCoachAccountSettings(vm.supplementUser.coach.id).then(function (res) {
                if (res.status === 200) {
                    vm.entityId = res.data.gymId;
                    // set value for vm.paymentFlowType and vm.isStripeUsed, need for correct work runFuncDependOnFlow
                    PaymentService.getPaymentFlowTypeAndIsStripeUsed(vm);
                    vm.currentCoach = res.data;
                    vm.getEntityInfo();
                }
            });
        }

        function getEntityInfo() {
            vm.spinner.active = true;
            CoachService.getCurrentEntityInfo(vm.customerId).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    for (var key in res.data.subscriptions) {
                        var subs = res.data.subscriptions[key];

                        subs.minSetupValue = vm.getMaxValue(subs.minChargeSetup, subs.setUpEntityPrice, vm.currentCoach.additional.setupDiscountPercent) / 100;
                        subs.minInitValue = vm.getMaxValue(subs.minChargeInit, subs.initInvoiceEntityPrice, vm.currentCoach.additional.membershipDiscountPercent) / 100;
                        subs.minRenewalValue = vm.getMaxValue(subs.minChargeRenew, subs.renewFeeEntityPrice, vm.currentCoach.additional.renewalDiscountPercent) / 100;

                        subs.setUpEntityPrice = subs.setUpEntityPrice / 100;
                        subs.setUpEkinPrice = subs.setUpEkinPrice / 100;
                        subs.initInvoiceEntityPrice = subs.initInvoiceEntityPrice / 100;
                        subs.initInvoiceEkinPrice = subs.initInvoiceEkinPrice / 100;
                        subs.renewFeeEntityPrice = subs.renewFeeEntityPrice / 100;
                        subs.renewFeeEkinPrice = subs.renewFeeEkinPrice / 100;
                        subs.minChargeSetup = subs.minChargeSetup / 100;
                        subs.minChargeInit = subs.minChargeInit / 100;
                        subs.minChargeRenew = subs.minChargeRenew / 100;
                    }

                    vm.gymDetails = res.data;
                    vm.initialSubscriptionsDetails = angular.copy(vm.gymDetails.subscriptions);
                }
            });
        }

        function pay() {
            PaymentService.runFuncDependOnFlow(vm.payNewFlow, vm.payOldFlow);
        }

        function payNewFlow() {
            const isShowStripe = PaymentService.getIsNeedStripePayForSubscription(vm.selectedSubscription.subscriptionType);
            if (isShowStripe) {
                const paymentRequestBody = {
                    subscriptionId: vm.selectedSubscription.id,
                    customerId: vm.supplementUser.id,
                    initialPrice: vm.selectedSubscription.initInvoiceEntityPrice,
                    renewalPrice: vm.selectedSubscription.renewFeeEntityPrice,
                };
                ModalService.showStripe(null, vm.successAddSubscriptionHandler, paymentRequestBody);
            } else {
                vm.createManualTransaction();
            }
        }

        function successAddSubscriptionHandler() {
            vm.getSubscriptionsList();
            vm.isChangeSubscriptionState = false;
        }

        function createManualTransaction() {
            vm.spinner.active = true;
            PaymentService.createManualTransaction(vm.selectedSubscription.id, vm.supplementUser.id)
              .then(res => {
                  if (res.status === 200) {
                      vm.successAddSubscriptionHandler();
                  }
              })
              .finally(() => vm.spinner.active = false);
        }

        function payOldFlow() {
            if (vm.handler && vm.selectedSubscription) {
                vm.setUpEntityPrice = Math.round(vm.selectedSubscription.setUpEntityPrice * 100);
                vm.initInvoiceEntityPrice = Math.round(vm.selectedSubscription.initInvoiceEntityPrice * 100);
                vm.renewFeeEntityPrice = Math.round(vm.selectedSubscription.renewFeeEntityPrice * 100);

                vm.handler.open({
                    email: vm.supplementUser.email,
                    name: vm.selectedSubscription.title,
                    description: vm.selectedSubscription.info,
                    amount: vm.setUpEntityPrice + vm.initInvoiceEntityPrice,
                    currency: 'usd'
                });
            }
        }

        function showUpdateCardDialog() {
            PaymentService.runFuncDependOnFlow(vm.showNewUpdateCardDialog, vm.showOldUpdateCardDialog);
        }

        function showNewUpdateCardDialog() {
            StripeService.updateCustomerCardDetailsByCoach(vm.supplementUser.id);
        }

        function showOldUpdateCardDialog() {
            vm.updatePaymentConf.open({
                email: vm.supplementUser.email,
                name: vm.supplementUser.firstName + ' ' + vm.supplementUser.lastName,
                description: 'Update payment source',
                currency: 'usd'
            });
        }

    }

})();
