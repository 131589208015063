// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../assets/images/vectors/login_picture_dark.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"reset-password-form-wrapper\" layout=\"row\" layout-align=\"space-between center\"> <form name=\"form\" class=\"reset-password-form\" novalidate ng-submit=\"vm.resetPassword(form)\"> <div class=\"reset-password-form__title\">Forgot Password</div> <md-input-container class=\"reset-password-form__email full-input\"> <label>Recovery e-mail</label> <input name=\"email\" type=\"email\" ng-model=\"vm.email\" required> <div ng-messages=\"form.email.$error\"> <p ng-message=\"required\">E-Mail is required</p> <p ng-message=\"email\">Invalid email address</p> <p ng-message=\"notFound\">Not existing email</p> </div> </md-input-container> <button class=\"reset-password-form__submit\" type=\"submit\" ng-disabled=\"form.$invalid\">Send</button> <a class=\"reset-password-form__link\" href=\"#!/\">Back to Login</a> </form> <img class=\"reset-password-image\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> </div> ";
// Exports
var _module_exports = code;;
var path = 'app/components/reset-password/reset-password.template.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;