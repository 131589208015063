import React from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { EntityListFormValues } from '../../../../interfaces/entity-list/EntityListFilters';
import { LbTabs, LbTabsList, TabOption } from '../../../../../shared/components/tabs';
import { FLAGS, useFlag } from '../../../../../shared/clients/unleash';

const PaymentFlowTabs = () => {
  const { control } = useFormContext<EntityListFormValues>();

  const {
    field: { value, onChange },
  } = useController({
    name: 'paymentFlowType',
    control,
  });

  const isShowNewFlow2024 = useFlag(FLAGS.SHOW_NEW_FLOW_2024);

  const tabsConfig: TabOption<EntityListFormValues['paymentFlowType']>[] = [
    { label: 'All', value: '' },
    { label: 'New Flow 2024', value: 'NEW_USER_FLOW_2024', hide: !isShowNewFlow2024 },
    { label: 'New Flow', value: 'NEW' },
    { label: 'Old Flow', value: 'OLD' },
  ];

  return (
    <LbTabs
      value={value}
      onChange={(event, selectedTab) => {
        onChange(selectedTab);
      }}
      data-test-id="payment-flow-tabs"
    >
      <LbTabsList tabsConfig={tabsConfig} variant="underline" />
    </LbTabs>
  );
};

export { PaymentFlowTabs };
