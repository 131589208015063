(function () {
    'use strict';

    angular.module('app.recipes').controller('RecipesController', RecipesController);

    RecipesController.$inject = ['$rootScope', '$location', 'RecipeService', 'utilService'];

    function RecipesController($rootScope, $location, RecipeService, utilService) {
        var vm = this;
        vm.recipes = [];
        vm.spinner = {active: false};
        vm.pagination = {};
        vm.pagesCount = 0;
        vm.loggedInUser = utilService.getLoggedInUserData();

        vm.retrieveRecipeList = retrieveRecipeList;
        vm.setRecipeState = setRecipeState;
        vm.selectRecipe = selectRecipe;
        vm.goTo = goTo;

        function retrieveRecipeList(page) {
            vm.spinner.active = true;
            RecipeService.getAllRecipes({page: page, owners: 'all'}).then(function (result) {
                vm.spinner.active = false;
                if (result.status === 200) {
                    vm.recipes = result.data.items;
                    vm.pagesCount = result.data.page.count;
                    if (page === 0) {
                        vm.pagination.reset(vm.pagesCount);
                    }
                }
            });
        }

        function setRecipeState(item) {
            RecipeService.changeRecipeState(item.id, {ban: item.banned}).then(function (result) {
                if (result.status !== 200) item.banned = !item.banned;
            });
        }

        function selectRecipe(recipeId) {
            $rootScope.$broadcast('selectRecipe', {recipeId: recipeId});
        }

        function goTo(path) {
            $location.path(path);
        }
    }
})();