import { ValueOfKey } from '../../utils-types';

export const USER_ACTIVATION_STATUSES = {
  PAYMENT_ISSUE_USER: 'payment_issue_user',
  PAYMENT_ISSUE_CA: 'payment_issue_ca',
  PAYMENT_ISSUE: 'payment_issue',
  INCOMPLETE_USER: 'incomplete_user',
  INCOMPLETE_CA: 'incomplete_ca',
  CANCELED_USER: 'canceled_user',
  ACTIVE_USER: 'active_user',
  ACTIVE_CA: 'active_ca',
  PREREGISTERED: 'preregistered',
} as const;

export type UserActivationStatusesType = ValueOfKey<typeof USER_ACTIVATION_STATUSES>;
