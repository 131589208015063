import React from 'react';
import { observer } from 'mobx-react';

import classes from '../CurrentPlanWidget.module.scss';
import { SubscriptionTypeChip } from '../../../../../components/SubscriptionTypeChip/SubscriptionTypeChip';
import { DiscountChip } from '../../../../../components/DiscountChip/DiscountChip';
import { currentSubscriptionPlanStore } from '../../../../../stores/currentSubscriptionPlanStore';
import { DISCOUNT_TYPE } from '../../../../../constants/discountType';

const GeneralSubscriptionInformation = observer(() => {
  const { currentSubscriptionPlan, subscriptionTypeFlags } = currentSubscriptionPlanStore;
  const { subscription, promocode } = currentSubscriptionPlan || {};

  if (!subscription) {
    return <h5 className={classes.title}>No Subscription</h5>;
  }

  return (
    <div>
      <h5 className={classes.title}>
        {subscription.name}
        <DiscountChip
          show={subscription.discountType === DISCOUNT_TYPE.PROMOCODE}
          discountPercent={promocode?.discountPercent}
        />
      </h5>
      {!subscriptionTypeFlags.FREE_ACCESS && (
        <SubscriptionTypeChip subscriptionType={subscription.subscriptionType} />
      )}
    </div>
  );
});

export { GeneralSubscriptionInformation };
