import React, { useState } from 'react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Button } from '@mui/material';

import classes from '../CurrentPlanWidget.module.scss';

type ApplySubscriptionsBlockProps = {
  onToggleModal: () => void;
  onSendViaEmail: () => Promise<void>;
};

const ApplySubscriptionsBlock = ({
  onToggleModal,
  onSendViaEmail,
}: ApplySubscriptionsBlockProps) => {
  const [isSent, setIsSent] = useState(false);

  const handleSendSubscriptionAccessViaEmail = async () => {
    await onSendViaEmail();
    setIsSent(true);
  };

  return (
    <div className={classes.manageRow}>
      <Button variant="contained" size="small" onClick={onToggleModal}>
        Explore Plans
      </Button>
      <Button
        disabled={isSent}
        variant="outlined"
        size="small"
        className={classes.sendButton}
        onClick={handleSendSubscriptionAccessViaEmail}
        startIcon={isSent && <CheckRoundedIcon />}
      >
        Send Subscription Access via email
      </Button>
    </div>
  );
};

export { ApplySubscriptionsBlock };
