import React from 'react';

import classNames from 'classnames';

import classes from './EmptyList.module.scss';
import emptyListImg from '../../../assets/image/empty-list.svg';

type EmptyListProps = {
  show: boolean;
  description: string;
  className?: string;
};

const EmptyList = ({ show, description, className }: EmptyListProps) => {
  if (!show) return null;

  const emptyListBoxClasses = classNames(classes.emptyListBox, className);

  return (
    <div className={emptyListBoxClasses}>
      <img src={emptyListImg} alt="Empty List" />
      <p className={classes.description}>{description}</p>
    </div>
  );
};

export { EmptyList };
