import React from 'react';

import { observer } from 'mobx-react';

import { LbModal } from '../../../../../shared/components/modals/LbModal/LbModal';
import { stepperStore } from '../../../../stores/stepperStore';
import { preCheckInService } from '../../../../services/preCheckInService';
import { goTo } from '../../../../../shared/utils/angularUtils';
import { apiStatusStore } from '../../../../../shared/stores/apiStatusStore';

const { setChildRegisterRequestStatus } = preCheckInService;

const DeclineRequestModal = observer(() => {
  const { isOpenDeclineModal, closeDeclineModal } = stepperStore;
  const { isLoadingGlobal } = apiStatusStore;

  const yesHandler = async () => {
    await setChildRegisterRequestStatus(false, true);
    closeDeclineModal();
    goTo('', true);
  };

  return (
    <LbModal
      open={isOpenDeclineModal}
      title="Do you want to decline this request?"
      text="In case of refusal, the coach receives a notification and, if necessary, will contact you to clarify the information."
      isLoading={isLoadingGlobal}
      primaryBtnConfig={{ text: 'Decline', onClick: yesHandler }}
      secondaryBtnConfig={{ onClick: closeDeclineModal }}
    />
  );
});

export { DeclineRequestModal };
