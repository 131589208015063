export const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';

export const dateFormat = 'YYYY-MM-DD';
export const timeFormat = 'HH:mm';

export const oldDateFormat = 'MM.DD.YYYY';

export const localTimeFormat = 'hh:mm a';
export const localDataFormat = 'MMM DD YYYY';
export const localDateTimeFormat = 'MMM DD YYYY hh:mm a';
