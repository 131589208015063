'use strict';

angular.module('app')
    .controller('CustomerProfileTabController', CustomerProfileTabController);

CustomerProfileTabController.$inject = [
    '$scope',
    '$routeParams',
    '$location',
    'utilService',
    'CorporateUsersService',
    'ModalService',
    'GeneralService',
    'PaymentService',
    'constants',
    'CustomerService',
    'CoachService',
    'CustomersFilterService',
    'CustomerGroupsService',
    'CustomerRoleService',
    'EntityService',
    'DateTimeUtil',
    'StripeService',
];

function CustomerProfileTabController($scope, $routeParams, $location, utilService, CorporateUsersService, ModalService,
                                      GeneralService, PaymentService, constants, CustomerService, CoachService,
                                      CustomersFilterService, CustomerGroupsService, CustomerRoleService, EntityService,
                                      DateTimeUtil, StripeService) {
    var vm = this;
    vm.enums = constants.enums;
    vm.loggedInUser = utilService.getLoggedInUserData();
    vm.complianceCoach = utilService.getComplianceCoachIndicator();
    vm.complianceCoachForCustomer = utilService.getComplianceCoachForCustomerIndicator();

    vm.superOrCoach = ['AGENT','SUPERUSER'].indexOf(vm.loggedInUser.role) >=0;

    vm.spinner = {active: false};
    vm.profileTabName = 'userProfile';
    vm.operatorPassword = {};
    vm.currentCustomerDetails = {};
    vm.isChangeSubscriptionState = false;
    vm.minUserAge = 12;
    vm.selectedSubscription = null;
    vm.gymDetails = {};
    vm.workoutTypes = {};
    vm.minDate = new Date(moment(new Date()).subtract(+100, 'years'));
    vm.maxDate = new Date();
    vm.inbodyTypes = constants.inbodyTypes;
    vm.currentSubscriptionType = {};
    vm.entityId = $scope.entityId;
    vm.customerId = $routeParams.id;
    vm.customerAvailableModules = $scope.customerAvailableModules;
    vm.paymentFlowType = {};
    vm.isStripeUsed = false;

    vm.init = init;
    vm.calculateExpirationPeriod = calculateExpirationPeriod;
    vm.checkInbodyId = checkInbodyId;
    vm.checkNumber = checkNumber;
    vm.getStripePublicKey = getStripePublicKey;
    vm.workoutOnFocus = workoutOnFocus;
    vm.getWeeklyWorkouts = getWeeklyWorkouts;
    vm.changeWeeklyWorkouts = changeWeeklyWorkouts;
    vm.initGymSubscriptions = initGymSubscriptions;
    vm.getMaxValue = getMaxValue;
    vm.selectSubscription = selectSubscription;
    vm.showDialog = showDialog;
    vm.showUpdateCardDialog = showUpdateCardDialog;
    vm.showNewUpdateCardDialog = showNewUpdateCardDialog;
    vm.showOldUpdateCardDialog = showOldUpdateCardDialog;
    vm.pay = pay;
    vm.payNewFlow = payNewFlow;
    vm.payOldFlow = payOldFlow;
    vm.createManualTransaction = createManualTransaction;
    vm.formatExpireDate = formatExpireDate;
    vm.paySubscriptionByUser = paySubscriptionByUser;
    vm.updateUserPaymentSource = updateUserPaymentSource;
    vm.getCoachAccountSettings = getCoachAccountSettings;
    vm.getEntityInfo = getEntityInfo;
    vm.getEntitiesPaymentTransaction = getEntitiesPaymentTransaction;
    vm.showDeleteCoachDialog = showDeleteCoachDialog;
    vm.deleteCurrentUser = deleteCurrentUser;
    vm.checkSubscriptions = checkSubscriptions;

    init();

    function init() {
        if(vm.customerAvailableModules.NUTRITION || vm.customerAvailableModules.SCHOLASTIC){
            initNutritionCustomer()
        }
    }

    function initNutritionCustomer() {
        // set value for vm.paymentFlowType and vm.isStripeUsed, need for correct work runFuncDependOnFlow
        PaymentService.getPaymentFlowTypeAndIsStripeUsed(vm);
        vm.getStripePublicKey();
    }

    function checkSubscriptions() {
        return vm.gymDetails.subscriptions.filter(function (value) {
            return value.active && value.subscriptionType === vm.currentSubscriptionType.value;
        }).length > 0;
    }

    function showDeleteCoachDialog() {
        ModalService.confirm('Are you sure you want to delete an user?',
            vm.deleteCurrentUser, $routeParams.id, null, 'Cancel', 'Delete')
    }

    function deleteCurrentUser(id) {
        vm.spinner.active = true;
        GeneralService.deleteUserBySuper(id).then(function (response) {
            vm.spinner.active = false;
            if (response.status === 200) {
                $location.path('/customers-dashboard');
            }
        })
    }

    function formatExpireDate(date) {
        return utilService.formatExpireDate(date);
    }

    function calculateExpirationPeriod(date) {
        return PaymentService.calculateExpirationPeriod(date);
    }

    function checkInbodyId(form, value) {
        if (value && value.trim().length > 0) {
            CustomerService.getInbodyData(value).then(function (res) {
                form.inbodyToken.$setValidity('unknown', res.status === 200);
            });
        }
    }

    function checkNumber(value, name) {
        if (!value) {
            vm.workoutTypes[name] = 0;
        }
    }

    function getStripePublicKey() {
        GeneralService.getStripePublicKey().then(function (res) {
            if (res.status === 200 && res.data.key) {
                vm.handler = StripeCheckout.configure({
                    key: res.data.key,
                    image: 'https://content.lifebase.solutions/static-content/logo.png',
                    locale: 'auto',
                    token: vm.paySubscriptionByUser
                });

                vm.updatePaymentConf = StripeCheckout.configure({
                    key: res.data.key,
                    image: 'https://content.lifebase.solutions/static-content/logo.png',
                    locale: 'auto',
                    panelLabel: 'Update',
                    source: vm.updateUserPaymentSource
                });
            }
        });
        vm.getWeeklyWorkouts();
        vm.initGymSubscriptions();
    }

    function updateUserPaymentSource(src) {
        PaymentService.updateUserPaymentSource(vm.currentCustomerDetails.id, {src: src.id}).then(function (res) {
            if (res.status === 402) {
                ModalService.confirm('Error Message', vm.showUpdateCardDialog, null, res.data.message, null, 'Enter card details');
            }
        })
    }

    function paySubscriptionByUser(token) {
        vm.spinner.active = true;
        PaymentService.paySubscriptionByUser($routeParams.id, {
            setup_price: vm.setUpEntityPrice,
            init_invoice_price: vm.initInvoiceEntityPrice,
            renew_fee_price: vm.renewFeeEntityPrice,
            token: token.id,
            subscription: vm.selectedSubscription.id
        }).then(function (res) {
            vm.spinner.active = false;
            vm.isChangeSubscriptionState = false;

            switch (res.status) {
                case 200:
                    vm.initGymSubscriptions();
                    break;
                case 402:
                    ModalService.confirm('Error Message', vm.pay, null, res.data.message, null, 'Enter card details');
                    break;
            }
        });
    }

    function workoutOnFocus(value, type) {
        if (value === 0) {
            vm.workoutTypes[type] = null;
        }
    }

    function getWeeklyWorkouts() {
        CustomerRoleService.getType($routeParams.id).then(function (res) {
            if (res.status === 200) {
                vm.workoutTypes = res.data;
            }
        });
    }

    function changeWeeklyWorkouts() {
        CustomerRoleService.changeType($routeParams.id, vm.workoutTypes);
    }

    function initGymSubscriptions() {
        vm.isChangeSubscriptionState = false;
        vm.spinner.active = true;
        CustomerService.getCustomerDetails(vm.loggedInUser.id, $routeParams.id).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.currentCustomerDetails = res.data;
                vm.currentSubscriptionType = vm.currentCustomerDetails.customer.inbodyType === vm.inbodyTypes.NON_INBODY.value ?
                    {name: 'non-inbody', value: 'NON_INBODY'} : {name: 'nutrition', value: 'NUTRITION'};

                if (!vm.complianceCoach && !vm.complianceCoachForCustomer) {
                    if (vm.currentCustomerDetails.customer.corporateSubscription) {
                        vm.getEntitiesPaymentTransaction();
                    }
                    vm.getCoachAccountSettings();
                }
            }
        });
    }

    function getCoachAccountSettings() {
        CoachService.getCoachAccountSettings(vm.currentCustomerDetails.coach.id).then(function (res) {
            if (res.status === 200) {
                vm.currentCoach = res.data;
                vm.getEntityInfo();
            }
        });
    }

    function getEntityInfo() {
        CoachService.getCurrentEntityInfo(vm.customerId).then(function (res) {
            if (res.status === 200) {
                for (var key in res.data.subscriptions) {
                    var subs = res.data.subscriptions[key];

                    subs.minSetupValue = vm.getMaxValue(subs.minChargeSetup, subs.setUpEntityPrice, vm.currentCoach.additional.setupDiscountPercent) / 100;
                    subs.minInitValue = vm.getMaxValue(subs.minChargeInit, subs.initInvoiceEntityPrice, vm.currentCoach.additional.membershipDiscountPercent) / 100;
                    subs.minRenewalValue = vm.getMaxValue(subs.minChargeRenew, subs.renewFeeEntityPrice, vm.currentCoach.additional.renewalDiscountPercent) / 100;

                    subs.setUpEntityPrice = subs.setUpEntityPrice / 100;
                    subs.setUpEkinPrice = subs.setUpEkinPrice / 100;
                    subs.initInvoiceEntityPrice = subs.initInvoiceEntityPrice / 100;
                    subs.initInvoiceEkinPrice = subs.initInvoiceEkinPrice / 100;
                    subs.renewFeeEntityPrice = subs.renewFeeEntityPrice / 100;
                    subs.renewFeeEkinPrice = subs.renewFeeEkinPrice / 100;
                    subs.minChargeSetup = subs.minChargeSetup / 100;
                    subs.minChargeInit = subs.minChargeInit / 100;
                    subs.minChargeRenew = subs.minChargeRenew / 100;
                }

                vm.gymDetails = res.data;
                const subscriptions = vm.gymDetails.subscriptions;
                const customerInbodyType = vm.currentCustomerDetails.customer.inbodyType;
                vm.gymDetails.subscriptions = CustomerService.filterSubscriptions(subscriptions, customerInbodyType);
                vm.initialSubscriptionsDetails = JSON.parse(JSON.stringify(vm.gymDetails.subscriptions));
            }
        });
    }

    function getEntitiesPaymentTransaction() {
        CorporateUsersService.getEntitiesPaymentTransaction($routeParams.id).then(function (res) {
            if (res.status === 200) {
                vm.currentCustomerDetails.subscriptions = res.data.transactions;
            }
        })
    }

    function getMaxValue(minFee, fee, percent) {
        var feeWithPercent = fee * percent / 100;

        if (minFee > fee - feeWithPercent) return Math.ceil(minFee);
        else return Math.ceil(fee - feeWithPercent);
    }

    function selectSubscription(subscription) {
        vm.selectedSubscription = subscription;

        for (var i = 0; i < vm.gymDetails.subscriptions.length; i++) {
            var currentSubscription = vm.gymDetails.subscriptions[i];
            if (currentSubscription.id !== subscription.id) {
                currentSubscription.setUpEntityPrice = vm.initialSubscriptionsDetails[i].setUpEntityPrice;
                currentSubscription.initInvoiceEntityPrice = vm.initialSubscriptionsDetails[i].initInvoiceEntityPrice;
                currentSubscription.renewFeeEntityPrice = vm.initialSubscriptionsDetails[i].renewFeeEntityPrice;
            }
        }
    }

    function showDialog(subscription_name, description) {
        ModalService.getSubscriptionInfo({title: subscription_name, info: description});
    }

    function showUpdateCardDialog() {
        PaymentService.runFuncDependOnFlow(vm.showNewUpdateCardDialog, vm.showOldUpdateCardDialog);
    }

    function showNewUpdateCardDialog() {
        if (vm.loggedInUser.role === vm.enums.userRoles.SUPERUSER) {
            StripeService.updateCustomerCardDetailsBySuperAdmin(vm.entityId, vm.currentCustomerDetails.id);
        } else {
            StripeService.updateCustomerCardDetailsByCoach(vm.currentCustomerDetails.id);
        }
    }

    function showOldUpdateCardDialog() {
        vm.updatePaymentConf.open({
            email: vm.currentCustomerDetails.email,
            name: vm.currentCustomerDetails.firstName + ' ' + vm.currentCustomerDetails.lastName,
            description: 'Update payment source',
            currency: 'usd'
        });
    }

    function pay() {
        PaymentService.runFuncDependOnFlow(vm.payNewFlow, vm.payOldFlow);
    }

    function payNewFlow() {
        const isShowStripe = PaymentService.getIsNeedStripePayForSubscription(vm.selectedSubscription.subscriptionType);
        if (isShowStripe) {
            const paymentRequestBody = {
                subscriptionId: vm.selectedSubscription.id,
                customerId: vm.currentCustomerDetails.id,
                initialPrice: vm.selectedSubscription.initInvoiceEntityPrice,
                renewalPrice: vm.selectedSubscription.renewFeeEntityPrice,
            };
            ModalService.showStripe(null, vm.initGymSubscriptions, paymentRequestBody);
        } else {
           vm.createManualTransaction();
        }
    }

    function createManualTransaction() {
        vm.spinner.active = true;
        PaymentService.createManualTransaction(vm.selectedSubscription.id, vm.currentCustomerDetails.id)
          .then(res => {
              if (res.status === 200) {
                  vm.initGymSubscriptions();
              }
          })
          .finally(() => vm.spinner.active = false);
    }

    function payOldFlow() {
        if (vm.handler && vm.selectedSubscription) {
            vm.setUpEntityPrice = Math.round(vm.selectedSubscription.setUpEntityPrice * 100);
            vm.initInvoiceEntityPrice = Math.round(vm.selectedSubscription.initInvoiceEntityPrice * 100);
            vm.renewFeeEntityPrice = Math.round(vm.selectedSubscription.renewFeeEntityPrice * 100);

            vm.handler.open({
                email: vm.currentCustomerDetails.billingEmail,
                name: vm.selectedSubscription.title,
                description: vm.selectedSubscription.info,
                amount: vm.setUpEntityPrice + vm.initInvoiceEntityPrice,
                currency: 'usd'
            });
        }
    }

}
