import React from 'react';

import { Button, Paper } from '@mui/material';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import classes from './SetupLoginInfo.module.scss';
import { LbInput } from '../../../../../shared/components/form-components';
import Stars from '../../../../../shared/assets/image/stars.png';
import { entityLoginIdService } from '../../../../services/entityLoginIdService';
import { setupEntityStore } from '../../../../stores/setupEntityStore';
import { goTo } from '../../../../../shared/utils/angularUtils';
import {
  validateLoginId,
  validateLoginIdMassage,
} from '../../../../utils/reactHookFormUtils';

type SetupLoginInfoFromValue = { loginId: string };

const SetupLoginInfo = observer(() => {
  const { control, handleSubmit } = useForm<SetupLoginInfoFromValue>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { entityId } = setupEntityStore;

  if (!entityId) {
    throw new Error('Entity id is not defined');
  }

  const handleFromSubmit = handleSubmit(async ({ loginId }) => {
    await entityLoginIdService.setEntityLoginId(entityId, loginId);

    goTo(`/entity-profile/${entityId}`, true);
  });

  return (
    <Paper variant="box" className={classes.paper}>
      <img src={Stars} alt="stars" className={classes.stars} />
      <div className={classes.infoBox}>
        <h3>Almost done!</h3>
        <p>It remains to create an ID for quick login!</p>
      </div>
      <div>
        <LbInput
          control={control}
          label="Login ID"
          name="loginId"
          rules={{ required: 'Login ID is required', validate: { validateLoginId } }}
          helperText={validateLoginIdMassage}
          size="small"
        />
      </div>
      <Button variant="contained" size="medium" fullWidth onClick={handleFromSubmit}>
        Send Magic Link
      </Button>
    </Paper>
  );
});

export { SetupLoginInfo };
