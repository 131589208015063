 (function () {
     'use strict';
     angular
         .module('app.recommendedSteps')
         .controller('RecommendedStepsController', RecommendedStepsController);

     RecommendedStepsController.$inject = [
         '$location',
         '$routeParams',
         'utilService',
         'ModalService',
         'MealPlanService',
         'CoachService',
         'CustomerService',
     ];

     function RecommendedStepsController($location, $routeParams, utilService, ModalService,
                                         MealPlanService, CoachService, CustomerService) {

         var vm = this;

         vm.loggedInUser = utilService.getLoggedInUserData();

         vm.completeStep1 = false;
         vm.completeStep2 = false;
         vm.completeStep3 = false;
         vm.completeStep4 = false;
         vm.completeStep5 = false;
         vm.completeStep6 = false;

         vm.isOpenedStep1 = false;
         vm.isOpenedStep2 = false;
         vm.isOpenedStep3 = false;
         vm.isOpenedStep4 = false;

         vm.currentCustomerDetails = {};
         vm.spinner = {active: false};

         vm.getCustomerDetails = getCustomerDetails;
         vm.goToCustomerDashboard = goToCustomerDashboard;
         vm.onSuccessPasswordUpdate = onSuccessPasswordUpdate;
         vm.getMealPlansInfo = getMealPlansInfo;
         vm.updateMealPlan = updateMealPlan;
         vm.showDialogWithVideo = showDialogWithVideo;

         vm.getMealPlansInfo();
         vm.getCustomerDetails();

         function showDialogWithVideo(video) {
             if (video.id) {
                 ModalService.showDialogWithVideo(video);
             }
         }

         function getCustomerDetails() {
             CustomerService.getCustomerDetails(vm.loggedInUser.id, $routeParams.id).then(function (res) {
                 if (res.status === 200) {
                     vm.currentCustomerDetails = res.data;
                 }
             });
         }

         function updateMealPlan() {
             MealPlanService.updateMealPlan($routeParams.id, vm.mealPlansInfo).then(function (res) {
                 if (res.status === 200) {
                     vm.completeStep4 = true;
                     ModalService.savedChanges();
                 }
             })
         }

         function onSuccessPasswordUpdate() {
             vm.completeStep2 = true;
         }

         function goToCustomerDashboard() {
             $location.path('/customer-profile/' + $routeParams.id);
             ModalService.completeMealPlanSetUp();
         }

         function getMealPlansInfo() {
             return MealPlanService.getMealPlan($routeParams.id).then(function (res) {
                 if (res.status === 200) {
                     vm.mealPlansInfo = res.data;
                     vm.mealPlan = vm.mealPlansInfo.mealPlans[0];
                 }
             });
         }
     }
 })();
