import React from 'react';

import { observer } from 'mobx-react';

import { EntitySubscriptionList } from '../../../components/EntitySubscriptionList/EntitySubscriptionList';
import { LbPagination } from '../../../../shared/components/ui-components';
import { superSubscriptionsManagementStore } from '../../../stores/entitySubscriptionsManagementStore';
import { taxStore } from '../../../stores/taxStore';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';

const SuperSubscriptionList = observer(() => {
  const {
    subscriptionsList,
    filterMethods,
    changeActivationStatus,
    changeDisplayToUserStatus,
  } = superSubscriptionsManagementStore;
  const { appliedTaxRateInfo } = taxStore;
  const { isSuperAdmin } = loggedInUserDataStore;

  return (
    <div>
      <EntitySubscriptionList
        subscriptionsList={subscriptionsList}
        changeActivationStatus={changeActivationStatus}
        changeDisplayToUserStatus={changeDisplayToUserStatus}
        sortMethods={{
          getIsActive: filterMethods.getIsSortedBy,
          onSort: filterMethods.handleSort,
          nameKey: 'NAME',
          subscriptionTypeKey: 'SUBSCRIPTION_TYPE',
        }}
        appliedTaxRateInfo={appliedTaxRateInfo}
        disabledEdit={!isSuperAdmin}
      />
      <LbPagination
        {...filterMethods.getPaginationProps()}
        elementsPerPage={filterMethods.elementsPerPage}
        totalElements={filterMethods.totalElements}
        variant="inBox"
      />
    </div>
  );
});

export { SuperSubscriptionList };
