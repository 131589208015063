import React from 'react';

import classes from './DiscountChip.module.scss';

type DiscountChipProps = {
  discountPercent?: number | null;
  show: boolean;
};

const DiscountChip = ({ discountPercent, show }: DiscountChipProps) => {
  if (!show || !discountPercent) {
    return null;
  }

  return <p className={classes.discountChip}>-{discountPercent}% Off</p>;
};

export { DiscountChip, DiscountChipProps };
