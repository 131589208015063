import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { Container, Pagination } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './NgzCustomerCommunicationList.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { CustomerRow } from './CustomerRow/CustomerRow';
import { TitleRow } from './TitleRow/TitleRow';
import { FilterRow } from './FilterRow/FilterRow';
import { customerCommunicationStore } from '../../stores/customerCommunicationStore';
import { EmptyListPlaceholder } from '../../../shared/components/EmptyListPlaceholder/EmptyListPlaceholder';
import { CustomerCommunicationPageTitle } from './CustomerCommunicationPageTitle/CustomerCommunicationPageTitle';
import { useCustomFilter } from '../../../shared/features/custom-filter';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';

const NgzCustomerCommunicationList = observer(() => {
  const { initUserInfoFinished } = loggedInUserDataStore;
  const { customerList, getCustomerListInitData, filterMethod } =
    customerCommunicationStore;
  const {
    isShowPagination,
    isShowPlaceholder,
    emptyListText,
    isShowFilterRow,
    getPaginationProps,
  } = filterMethod;

  useCustomFilter('customers');

  useEffect(() => {
    if (initUserInfoFinished) {
      getCustomerListInitData();
    }
  }, [initUserInfoFinished]);

  const paginationProps = getPaginationProps();

  return (
    <SystemProviders>
      <Container>
        <CustomerCommunicationPageTitle />
        <div className={classes.customerTable}>
          <TitleRow />
          {isShowFilterRow && <FilterRow />}
          {customerList.map((userInfo) => (
            <CustomerRow userInfo={userInfo} key={userInfo.id} />
          ))}
        </div>
        {isShowPagination && <Pagination {...paginationProps} />}
        {isShowPlaceholder && <EmptyListPlaceholder text={emptyListText} />}
      </Container>
    </SystemProviders>
  );
});

angularize(
  NgzCustomerCommunicationList,
  'ngzCustomerCommunicationList',
  angular.module('app'),
  {},
);

export default NgzCustomerCommunicationList;
