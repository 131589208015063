import React, { useState } from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import {
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  useController,
} from 'react-hook-form';

import { getPasswordRules } from './PasswordInput.settings';
import { ShowPasswordButton } from './ShowPasswordButton/ShowPasswordButton';

type PasswordInputProps<T extends FieldValues, TName extends Path<T>> = {
  minLength?: number;
  maxLength?: number;
  helperText?: string;
} & UseControllerProps<T, TName> &
  Pick<TextFieldProps, 'label' | 'variant' | 'size' | 'margin'>;

const PasswordInput = <T extends FieldValues, TName extends Path<T>>({
  control,
  name,
  label,
  defaultValue = '' as PathValue<T, TName>,
  shouldUnregister,
  variant,
  size,
  margin,
  rules,
  helperText,
  minLength = 8,
  maxLength = 16,
}: PasswordInputProps<T, TName>) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules: getPasswordRules({ minLength, maxLength, rules }),
    defaultValue,
    shouldUnregister,
  });

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  return (
    <TextField
      {...field}
      inputRef={ref}
      helperText={error?.message || helperText}
      error={!!error}
      label={label}
      type={isShowPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <ShowPasswordButton
            isShowPassword={isShowPassword}
            onClick={handleClickShowPassword}
          />
        ),
      }}
      variant={variant}
      size={size}
      margin={margin}
      required
    />
  );
};

export { PasswordInput };
