import React, { ReactNode } from 'react';

import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import classes from './InfoTooltip.module.scss';

type InfoTooltipProps = {
  children: ReactNode;
};

const InfoTooltip = ({ children }: InfoTooltipProps) => {
  return (
    <Tooltip title={<div className={classes.textBox}>{children}</div>} placement="bottom">
      <InfoOutlinedIcon fontSize="small" color="tertiary" className={classes.icon} />
    </Tooltip>
  );
};

export { InfoTooltip, InfoTooltipProps };
