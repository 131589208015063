'use strict';

import { chartsDataList } from "./chartsDataList";
import { cloneDeep } from 'lodash';


angular.module('app')
    .controller('CustomerInbodyIndicatorsTabController', CustomerInbodyIndicatorsTabController);

CustomerInbodyIndicatorsTabController.$inject = [
    '$routeParams',
    'ChartService',
    'CustomerService',
    'ModalService',
    'ScenariosService',
    'DateTimeUtil',
    'utilService',
    '$scope',
    'constants'
];

function CustomerInbodyIndicatorsTabController($routeParams, ChartService, CustomerService,
                                               ModalService, ScenariosService, DateTimeUtil,
                                               utilService, $scope, constants) {
    var vm = this;
    vm.customerInbodyType = $scope.customerInbodyType;
    vm.inbodyTypes = constants.inbodyTypes;
    vm.isInbody270 = vm.customerInbodyType === vm.inbodyTypes.INBODY_270.value;
    vm.goalsByScans = [];
    vm.chartsDataList = cloneDeep(chartsDataList);
    vm.valuesCount = 14;
    vm.spinner = {active: true};
    vm.selectedCustomerProfile = JSON.parse(localStorage.getItem("selectedCustomerProfile"));

    vm.initBodyCompositionCharts = initBodyCompositionCharts;
    vm.changeScanDate = changeScanDate;
    vm.printPage = printPage;
    vm.buildCharts = buildCharts;
    vm.getGoalWithScans = getGoalWithScans;
    vm.getInbodyData = getInbodyData;
    vm.openScanSummary = openScanSummary;
    vm.processGetSuggestionResp = processGetSuggestionResp;
    vm.setInbodyDetails = setInbodyDetails;
    vm.processChartsDataList = processChartsDataList;
    vm.setScanDataToChart = setScanDataToChart;
    vm.getGoalByScanDay = getGoalByScanDay;
    vm.setIsShow = setIsShow;

    function initBodyCompositionCharts() {
        vm.getGoalWithScans().then(function () {
            vm.getInbodyData();
        })
    }

    function getGoalWithScans() {
        vm.spinner.active = true;
        return CustomerService.getUserGoalsWithScanDates($routeParams.id).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.goalsByScans = res.data;
            }
        })
    }

    function getInbodyData() {
        vm.spinner.active = true;
        CustomerService.getUserInbodyMetric($routeParams.id).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                const userInbodyMetric = res.data;
                vm.scanDates = userInbodyMetric.dates;
                vm.indicatorData = userInbodyMetric.data;
                vm.setInbodyDetails(userInbodyMetric);
                vm.processChartsDataList();

                const lastScan = vm.scanDates.length ? vm.scanDates.length - 1 : 0;
                vm.selectedDate = lastScan;
                vm.buildCharts(lastScan - vm.valuesCount - 1, vm.valuesCount - 1);
            }
        });
    }

    function setInbodyDetails (userInbodyMetric) {
        vm.inbodyDetails = {
            inbody_id: userInbodyMetric.id,
            height: Math.round(userInbodyMetric.height * 100) / 100 + 'in',
            age: userInbodyMetric.age,
            gender: userInbodyMetric.gender
        };
    }

    function processChartsDataList() {
        vm.indicatorData.forEach(vm.setScanDataToChart);
        vm.setIsShow();
    }

    function setScanDataToChart(scanData) {
        vm.chartsDataList.forEach((chart) => {
            const date = DateTimeUtil.formatDate(scanData.scanOn,'MM.DD.YYYY');
            const value = scanData[chart.dbName];

            if (chart.chartId === 'weightChart') {
                const { proteins, carbs, fats } = vm.getGoalByScanDay(scanData.scanOn);
                chart.data.push({ date, value, proteins, carbs, fats });
            } else {
                chart.data.push({ date, value });
            }
        });
    }

    function setIsShow() {
        const inbodyHiddenChartIds = {
            [vm.inbodyTypes.INBODY_270.value]: ['ecwTbwChart', 'totalWaterChart', 'icwChart', 'ecwChart'],
        };
        const hiddenChartIds = inbodyHiddenChartIds[vm.customerInbodyType] ?? [];

        vm.chartsDataList.forEach((chart) => {
            chart.isShow = !hiddenChartIds.includes(chart.chartId);
        });
    }   

    function getGoalByScanDay(scanDate) {
            const goal = vm.goalsByScans.find((goal) => moment(goal.date, 'YYYY-MM-DD').isSame(scanDate, 'MM/DD/YYYY'));

            return goal ?? { proteins: 0, carbs: 0, fats: 0 };
    }

    function changeScanDate() {
        var startIndex = vm.selectedDate - vm.valuesCount - 1;

        if (startIndex >= 0) {
            vm.buildCharts(startIndex, vm.valuesCount - 1);
        } else {
            vm.buildCharts(0, vm.valuesCount + startIndex - 1);
        }
    }

    function printPage() {
        window.document.body.setAttribute("style", "width:285mm");
        setTimeout(function () {
            window.print();
            window.document.body.setAttribute("style", "width:inherit");
        }, 200);
    }

    function buildCharts(startIndex, activeIndex) {
        vm.chartsDataList.forEach((chart) => {
            if (chart.isShow) {
                chart.instance = ChartService.createBodyCompositionChart(chart, startIndex, activeIndex);
            }
        });        
    }

    async function openScanSummary() {
        const initialDate = vm.scanDates[vm.selectedDate];
        const formattedDate = DateTimeUtil.convertDateFormat(initialDate, 'MM.DD.YYYY');
        const params = {
            vm,
            func: ScenariosService.getSuggestionForUser,
            args: [$routeParams.id, formattedDate],
        };

        const resp = await utilService.getAsyncResult(params);

        if (resp) {
            vm.processGetSuggestionResp(resp);
        }
    }

    function processGetSuggestionResp(resp) {
        vm.suggestScenario = resp;
        ModalService.suggestScenario(vm);
    }
}
