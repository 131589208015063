import React from 'react';

import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';

import classes from './SetPasswordForm.module.scss';
import { TextInfo } from '../../components/TextInfo/TextInfo';
import { passwordServices } from '../../../../services/passwordServices';
import { PasswordInput } from '../../../../../shared/components/form-components';
import { AccessParams } from '../../../../../shared/interfaces/AccessParams';

export type SetPasswordFormFields = {
  password: 'string';
  confirmPassword: 'string';
};

export type SetPasswordFormProps = {
  goToNextStep: () => void;
  accessParams: AccessParams;
};

const SetPasswordForm = ({ goToNextStep, accessParams }: SetPasswordFormProps) => {
  const { control, handleSubmit } = useForm<SetPasswordFormFields>({
    mode: 'onChange',
  });

  const onSubmit = async (data: SetPasswordFormFields) => {
    await passwordServices.setPassword(accessParams, data.password);

    goToNextStep();
  };

  const validateConfirmPassword = (
    confirmPassword: string,
    formValue: SetPasswordFormFields,
  ) => {
    return confirmPassword === formValue.password || "Passwords don't match";
  };

  return (
    <>
      <TextInfo
        title="Set the password"
        text="To use the LifeBase Solutions app, please set up a password below"
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.setPasswordForm}
        noValidate
      >
        <PasswordInput
          label="New Password"
          minLength={8}
          maxLength={13}
          control={control}
          name="password"
          helperText="Length must be between 8 and 13 characters"
        />
        <PasswordInput
          label="Confirm Password"
          minLength={8}
          maxLength={13}
          control={control}
          name="confirmPassword"
          rules={{
            validate: validateConfirmPassword,
          }}
        />
        <Button type="submit" variant="contained">
          Set the Password
        </Button>
      </form>
    </>
  );
};

export { SetPasswordForm };
