import React from 'react';
import PropTypes from 'prop-types';

import classes from './CorporateAccountsModalForm.module.scss';
import {
  LbInput,
  PasswordInput,
} from '../../../../../../../shared/components/form-components';

const CorporateAccountsModalForm = ({ control }) => {
  return (
    <form className={classes.corporateAccountsModalForm}>
      <LbInput
        placeholder="Login"
        control={control}
        name="login"
        rules={{
          required: 'Login is required',
          minLength: { value: 5, message: 'Login must be at least 5 symbols' },
          maxLength: { value: 20, message: 'Login must be under 16 symbols' },
        }}
      />
      <PasswordInput
        label="Password"
        control={control}
        name="password"
        minLength={5}
        maxLength={20}
      />
    </form>
  );
};

CorporateAccountsModalForm.propTypes = {
  control: PropTypes.object,
};

export { CorporateAccountsModalForm };
