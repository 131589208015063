(function () {
    'use strict';

    angular.module('app.salesDashboard')
        .controller('SalesDashboardController', SalesDashboardController);

    SalesDashboardController.$inject = [
        '$scope',
        '$location',
        'utilService',
        'EntityService',
        'SalesService',
    ];

    function SalesDashboardController($scope, $location, utilService, EntityService, SalesService) {
        var vm = this;
        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.isEntity = utilService.getIsAdminRole();
        vm.spinner = {active: false};
        vm.minDate = new Date(moment(new Date()).subtract(+100, 'years'));
        vm.selectedDateRange = {
            start: new Date(),
            end: new Date()
        };
        vm.mobileTabs = [false, false, false, false];
        vm.isChildEntity = utilService.getIsChildEntity();
        vm.paymentFlowType = utilService.getPaymentFlowType();
        vm.isUnderConstruction = vm.isEntity && vm.paymentFlowType.NEW_USER_FLOW_2024;

        vm.formatDateWithDot = formatDateWithDot;
        vm.formatDateForRequest = formatDateForRequest;
        vm.goTo = goTo;
        vm.getEntitiesBasics = getEntitiesBasics;
        vm.getDashboard = getDashboard;
        vm.getPreviousEntity = getPreviousEntity;
        vm.getTransactions = getTransactions;
        vm.updateSalesDashboard = updateSalesDashboard;
        vm.downloadReport = downloadReport;
        vm.closeDatePicker = closeDatePicker;
        vm.getDeposits = getDeposits;
        vm.initSalesDashboardData = initSalesDashboardData;

        vm.initSalesDashboardData();
       
        function initSalesDashboardData() {
            if (vm.isUnderConstruction) {
                return;
            }

          switch (vm.loggedInUser.role) {
                case 'SUPERUSER':
                    vm.getEntitiesBasics();
                    break;
                case 'ADMIN':
                    vm.updateSalesDashboard();
                    break;
            }
        }

        function closeDatePicker(dateVariable) {
            $scope.$evalAsync(function () {
                vm[dateVariable + 'IsOpen'] = false;
            });
        }

        function formatDateWithDot(date) {
            return moment(date).format('MM.DD.YYYY');
        }

        function formatDateForRequest(date) {
            return moment(date).format('YYYY-MM-DD');
        }

        function goTo(page) {
            localStorage.setItem('gym', JSON.stringify(vm.selectedEntityTab));
            $location.path('/' + page + '/from/' + formatDateForRequest(vm.selectedDateRange.start) + '/to/' + formatDateForRequest(vm.selectedDateRange.end));
        }

        function getEntitiesBasics() {
            EntityService.getEntitiesBasics().then(function (res) {
                if (res.status === 200) {
                    vm.allEntities = angular.copy(res.data.entities);
                    vm.allEntities.unshift({id: 0, business_name: 'All entities'});
                    vm.selectedEntityTab = vm.allEntities[0];
                    vm.previousEntity = vm.allEntities[0];
                    vm.currentEntity = vm.allEntities[0];
                    vm.updateSalesDashboard();
                }
            });
        }

        function getDashboard() {
            SalesService.getDashboard({
                entity_id: vm.loggedInUser.role === 'SUPERUSER' ? vm.selectedEntityTab.id : undefined,
                from: formatDateForRequest(vm.selectedDateRange.start),
                to: formatDateForRequest(vm.selectedDateRange.end),
            }).then(function (res) {
                if (res.status === 200) {
                    vm.dashboard = res.data;
                }
            });
        }

        function getPreviousEntity() {
            if (vm.previousEntity.business_name !== 'All entities') {
                vm.selectedEntityTab = angular.copy(vm.previousEntity);
                vm.currentEntity = angular.copy(vm.previousEntity);
            } else {
                vm.selectedEntityTab = vm.allEntities[0];
                vm.currentEntity = vm.allEntities[0];
            }
        }

        function getTransactions() {
            SalesService.getTransactions({
                from: vm.formatDateForRequest(vm.selectedDateRange.start),
                to: vm.formatDateForRequest(vm.selectedDateRange.end),
                entity_id: vm.loggedInUser.role === 'SUPERUSER' ? vm.selectedEntityTab.id : undefined,
            }).then(function (res) {
                if (res.status === 200) {
                    vm.incomeTransactions = res.data.deposits;
                    vm.getDeposits();
                } else if (vm.loggedInUser.role === 'SUPERUSER') {
                    vm.getPreviousEntity();
                }
            });
        }

        function getDeposits() {
            SalesService.getDeposits({
                from: vm.formatDateForRequest(vm.selectedDateRange.start),
                to: vm.formatDateForRequest(vm.selectedDateRange.end),
                limit: 20,
                entity_id: vm.loggedInUser.role === 'SUPERUSER' ? vm.selectedEntityTab.id : undefined,
            }).then(function (res) {
                if (res.status === 200) {
                    vm.deposits = res.data.deposits;
                } else if (vm.loggedInUser.role === 'SUPERUSER') {
                    vm.getPreviousEntity();
                }
            });
        }

        function updateSalesDashboard() {
          if (vm.loggedInUser.role === 'SUPERUSER') {
            vm.previousEntity = angular.copy(vm.currentEntity);
            vm.currentEntity = angular.copy(vm.selectedEntityTab);
          }

          vm.getDashboard();
          vm.getTransactions();
        }

        function downloadReport(reportType, fileType) {
            vm.spinner.active = true;
            SalesService.getDownload(reportType, fileType, {
                from: vm.formatDateWithDot(vm.selectedDateRange.start),
                to: vm.formatDateWithDot(vm.selectedDateRange.end),
                entity_id: vm.loggedInUser.role === 'SUPERUSER' && vm.selectedEntityTab.id > 0 ? vm.selectedEntityTab.id : undefined,
            }).then(function () {
                vm.spinner.active = false;
            });
        }
    }
})();
