'use strict';

angular.module('app.leaderboard').controller('LeaderboardController', LeaderboardController);
LeaderboardController.$inject = [
    '$rootScope',
    '$scope',
    '$routeParams',
    '$location',
    'utilService',
    'StatsService',
    'EntityService',
    'CoachService',
    'constants'
];

function LeaderboardController($rootScope, $scope, $routeParams, $location, utilService, StatsService, EntityService, CoachService, constants) {
    var vm = this;

    vm.isCurrentGoalActive = $scope.isCurrentGoalActive;

    vm.customerId = $routeParams.id;
    vm.loggedInUser = utilService.getLoggedInUserData();
    vm.currentCustomer = null;
    vm.allEntities = [{id: -1, business_name: 'All entities'}];
    vm.selectedEntityTab = {};
    vm.leaderboard = [];
    vm.startDateIsOpen = false;
    vm.endDateIsOpen = false;
    vm.spinner = {active: false};

    vm.pagination = {};
    vm.states = ['All states'].concat(constants.states);
    vm.prms = {
        skip: 0,
        sort: '',
        sortField: '',
        name: '',
        state: vm.states[0],
        offset: 10,
        endDate: new Date(),
        startDate: new Date(new Date().setDate(new Date().getDate() - 30))
    };

    vm.initLeaderboardData = initLeaderboardData;
    vm.getLeaderboardData = getLeaderboardData;
    vm.getCurrentCustomer = getCurrentCustomer;
    vm.formatDate = formatDate;
    vm.setSortConditions = setSortConditions;
    vm.goTo = goTo;
    vm.getEntitiesBasics = getEntitiesBasics;
    vm.getCurrentEntityInfo = getCurrentEntityInfo;
    vm.closeDatePicker = closeDatePicker;
    vm.initMaxNumOfRows = initMaxNumOfRows;

    vm.initMaxNumOfRows();
    vm.initLeaderboardData();

    function initMaxNumOfRows() {
        vm.maxNumOfRows = $location.path().indexOf('/superuser-dashboard') > -1 ? 3 : 8;
    }

    function initLeaderboardData() {
        switch (vm.loggedInUser.role) {
            case 'SUPERUSER':
                vm.getEntitiesBasics();
                break;
            case 'AGENT':
                vm.getCurrentEntityInfo();
                break;
        }
    }

    function goTo(path) {
        $location.path(path);
    }

    function closeDatePicker(dateVariable) {
        $scope.$evalAsync(function () {
            vm[dateVariable + 'IsOpen'] = false;
        });
    }

    function setSortConditions(field, sort) {
        vm.prms.sortField = field;
        vm.prms.sort = sort;
        vm.getLeaderboardData(0);
    }

    function formatDate(date, template) {
        return utilService.formatDate(date, template);
    }

    function getCurrentCustomer() {
        StatsService.getStatsLeaderboardUserPosition(vm.customerId).then(function (result) {
            if (result.status === 200) {
                vm.currentCustomer = result.data;
            }
        });
    }

    function getEntitiesBasics() {
        vm.spinner.active = true;
        EntityService.getEntitiesBasics().then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.allEntities = angular.copy(res.data.entities);
                vm.allEntities.unshift({id: -1, business_name: 'All entities'});
                vm.selectedEntityTab = vm.allEntities[0];
                vm.getLeaderboardData(0);
                if (vm.customerId) {
                    vm.getCurrentCustomer();
                }
            }
        });
    }

    function getCurrentEntityInfo() {
        vm.spinner.active = true;
        CoachService.getCurrentEntityInfo().then(function (result) {
            vm.spinner.active = false;
            if (result.status === 200) {
                vm.allEntities.push(result.data);
                vm.selectedEntityTab = result.data;
                vm.getLeaderboardData(0);
                if (vm.customerId) {
                    vm.getCurrentCustomer();
                }
            }
        });
    }

    function getLeaderboardData(skip) {
        if (vm.selectedEntityTab) {
            vm.prms.skip = skip;

            var prms = angular.copy(vm.prms);

            if (vm.selectedEntityTab.id !== -1) prms.entityId = vm.selectedEntityTab.id;
            if (vm.prms.startDate) prms.startDate = vm.formatDate(vm.prms.startDate, 'MM-DD-YYYY');
            if (vm.prms.endDate) prms.endDate = vm.formatDate(vm.prms.endDate, 'MM-DD-YYYY');
            vm.prms.state !== 'All states' ? prms.state = vm.prms.state : delete prms.state;

            vm.spinner.active = true;
            StatsService.getStatsLeaderboardBySuperadmin(prms).then(function (result) {
                vm.spinner.active = false;
                if (result.status === 200) {
                    if (result.data.length === 0 && vm.prms.skip > 0) {
                        $rootScope.$broadcast('goBack');
                    } else {
                        vm.leaderboard = result.data;
                        vm.pagination.reset(vm.leaderboard.length, vm.prms.skip);
                    }
                }
            });
        }
    }


}
