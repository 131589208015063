import { getIsNullish } from '../../jsUtils';
/**
 * return prise or empty string
 * use getPriceOrDash whe need to show dash if price not exist
 * */
export const getPriceView = (price: number | string | null | undefined): string => {
  if (getIsNullish(price)) {
    return '';
  }

  const parsedPrice = Number(price);

  return Number.isNaN(parsedPrice) ? price.toString() : `$${parsedPrice.toFixed(2)}`;
};
