import React, { useEffect } from 'react';

import classes from './Timer.module.scss';
import { useTimer } from './hooks/useTimer';
import {
  getRouteParam,
  goToWithParams,
} from '../../../../../../shared/utils/angularUtils';
import { pathQueryParams } from '../../../../../constants/pathQueryParams';

type TimerProps = {
  expirationEpoch: number;
};

const Timer = ({ expirationEpoch }: TimerProps) => {
  const retryPath = getRouteParam(pathQueryParams.retryPath);

  const { minutes, seconds, isTimeOver } = useTimer(expirationEpoch);

  useEffect(() => {
    if (isTimeOver) {
      goToWithParams('/payment-result', {
        [pathQueryParams.redirectStatus]: 'failed',
        retryPath,
      });
    }
  }, [isTimeOver]);

  return (
    <div className={classes.container}>
      <div className={classes.timeContainer}>
        <p className={classes.time}>{minutes}</p>
        <p className={classes.description}>minutes</p>
      </div>
      <p className={classes.divider}>:</p>
      <div className={classes.timeContainer}>
        <p className={classes.time}>{seconds}</p>
        <p className={classes.description}>seconds</p>
      </div>
    </div>
  );
};

export { Timer };
