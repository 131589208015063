(function () {
    'use strict';

    angular.module('app.coachProfile').controller('CoachProfileController', CoachProfileController);

    CoachProfileController.$inject = [
        '$location',
        'utilService',
        'AuthService',
        'CoachService',
        'GeneralService',
        '$window',
        'PaidModulesUtil',
        'constants',
        'BaseUserUtils',
    ];

    function CoachProfileController($location, utilService, AuthService, CoachService, GeneralService, $window, PaidModulesUtil, constants, BaseUserUtils) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.patterns = constants.enums.patterns;
        vm.coachAccountSettings = {};
        vm.coachPassword = {};
        vm.isActiveCalendarEnabled = false;

        vm.goToMoodleAccount = goToMoodleAccount;
        vm.checkPassword = checkPassword;
        vm.getCoachAccountSettings = getCoachAccountSettings;
        vm.updateCoachAccountSettings = updateCoachAccountSettings;
        vm.updateCoachPassword = updateCoachPassword;
        vm.initTabName = initTabName;
        vm.changeTab = changeTab;
        vm.changeAvatar = changeAvatar;
        vm.setEnabledModules = setEnabledModules;

        vm.initTabName();
        vm.getCoachAccountSettings();
        vm.setEnabledModules();

        function changeAvatar() {
            BaseUserUtils.updateAvatar(vm.userAvatar, vm.loggedInUser);
        }

        function goToMoodleAccount(path) {
            $window.open(path, '_blank');
        }

        function initTabName() {
            vm.tabName = $location.url() === '/coach-profile/notes' ? 'notes' : 'accountSettings';
        }

        function checkPassword(form, password, repeatPassword) {
            GeneralService.checkPassword(form, password, repeatPassword, vm.coachPassword);
        }

        function getCoachAccountSettings() {
            CoachService.getCoachAccountSettings(vm.loggedInUser.id).then(function (res) {
                if (res.status === 200) {
                    vm.coachAccountSettings = res.data;
                }
            });
        }

        function updateCoachAccountSettings() {
            CoachService.updateCoachAccountSettings(vm.loggedInUser.id, vm.coachAccountSettings).then(function (res) {
                if (res.status === 200) {
                    vm.loggedInUser = res.data;
                }
            });
        }

        function updateCoachPassword(form) {
            GeneralService.changePassword(vm.coachPassword).then(function (res) {
                if (res.data.message === 'Current password is wrong') {
                    form['currentPassword'].$setValidity('wrong', false);
                }
            });
        }

        function changeTab(tabName) {
            vm.tabName = tabName;
            if (vm.tabName === 'password') {
                vm.coachPassword = {};
            }
        }

        function setEnabledModules () {
            vm.isActiveCalendarEnabled = PaidModulesUtil.getIsActiveCalendarEnabled();
        }
    }
})();
