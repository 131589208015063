import React, { ReactNode } from 'react';

import { Modal, ModalProps, Paper } from '@mui/material';
import { isUndefined } from 'lodash';

import classes from './LbBigModal.module.scss';
import { Spinner } from '../../Spinner/Spinner';
import { YesNoBtnRow, YesNoBtnRowProps } from '../../buttons';
import { ScrollBox } from '../../ui-components';

type LbModalProps = {
  title: string;
  description?: ReactNode;
  children: ReactNode;
  buttonOptions?: Omit<YesNoBtnRowProps, 'children' | 'size'>;
  extraButton?: ReactNode;
  isLoading?: boolean;
  renderButtonRow?: ReactNode;
} & Pick<ModalProps, 'open' | 'onClose'>;

const LbBigModal = ({
  open,
  title,
  description,
  children,
  onClose,
  buttonOptions = {},
  extraButton,
  isLoading = false,
  renderButtonRow,
}: LbModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper variant="box" className={classes.modalPaper}>
        <div className={classes.modalInfo}>
          <h3>{title}</h3>
          {description && <p>{description}</p>}
        </div>
        <ScrollBox maxHeight={560} variant="outBox">
          {children}
        </ScrollBox>
        {isUndefined(renderButtonRow) ? (
          <YesNoBtnRow {...buttonOptions} size="medium">
            {extraButton}
          </YesNoBtnRow>
        ) : (
          renderButtonRow
        )}
        <Spinner isLoading={isLoading} />
      </Paper>
    </Modal>
  );
};

export { LbBigModal };
