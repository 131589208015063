'use strict';

angular.module('app.entityStats').controller('EntityStatsController', EntityStatsController);

EntityStatsController.$inject = ['$location', 'utilService', 'EntityService', 'ModalService', 'StatsService'];

function EntityStatsController($location, utilService, EntityService, ModalService, StatsService) {
    var vm = this;
    vm.userRole = utilService.getLoggedInUserData().role;
    vm.spinner = {active: false};
    vm.searchState = false;
    vm.entitiesByAdmin = [];
    vm.entitiesName = [{ids: [], name: 'All entities'}];
    vm.selectedEntityName = vm.entitiesName[0];

    vm.getEntities = getEntities;
    vm.changeSelectedEntity = changeSelectedEntity;
    vm.getEntityDetailById = getEntityDetailById;
    vm.getEntitiesInfo = getEntitiesInfo;
    vm.getAllEntitiesDetails = getAllEntitiesDetails;
    vm.disableStripeConnectPlatformAgreement = disableStripeConnectPlatformAgreement;
    vm.searchInputEntity = searchInputEntity;
    vm.goTo = goTo;
    getEntities();

    function getEntities() {
        vm.entitiesName = [{ids: [], name: 'All entities'}];
        EntityService.getEntitiesBasics().then(function (res) {
            if (res.status === 200) {
                vm.entitiesByAdmin = res.data.entities;
                vm.selectedEntityName = vm.entitiesName[0];
                vm.allEntitiesList = vm.entitiesName;
                vm.getAllEntitiesDetails();
                vm.getEntitiesInfo(0);
            }
        });
    }

    function changeSelectedEntity(entity) {
        vm.selectedEntityName = entity;
        if (vm.selectedEntityName.name === 'All entities') {
            vm.getEntities();
        } else {
            vm.entitiesByAdmin = [];
            for (var i in vm.selectedEntityName.ids) {
                vm.selectedEntityName.ids[i].business_name = vm.selectedEntityName.name;
                vm.entitiesByAdmin.push(vm.selectedEntityName.ids[i]);
                vm.entitiesByAdmin[i].accepted_stripe_terms = vm.selectedEntityName.ids[i].accepted_stripe_terms;
                vm.getEntityDetailById(i);
            }
        }
    }

    function getEntityDetailById(i) {
        StatsService.getEntityDetailsById(vm.entitiesByAdmin[i].id).then(function (res) {
            if (res.status === 200) {
                vm.entitiesByAdmin[i].details = angular.copy(res.data.data);
                vm.entitiesByAdmin[i].details.push(res.data.total);
            }
        });
    }

    function getEntitiesInfo(i) {
        if (vm.entitiesByAdmin[i]) {
            var index = utilService.findWithAttr(vm.entitiesName, 'name', vm.entitiesByAdmin[i].business_name);
            if (index > -1) {
                vm.entitiesName[index].ids.push({
                    id: vm.entitiesByAdmin[i].id,
                    accepted_stripe_terms: vm.entitiesByAdmin[i].accepted_stripe_terms
                });
            } else {
                vm.entitiesName.push({
                    ids: [{
                        id: vm.entitiesByAdmin[i].id,
                        accepted_stripe_terms: vm.entitiesByAdmin[i].accepted_stripe_terms
                    }],
                    name: vm.entitiesByAdmin[i].business_name
                });
            }

            vm.getEntitiesInfo(i + 1);
        }
    }

    function getAllEntitiesDetails() {
        StatsService.getAllEntitiesDetails().then(function (res) {
            if (res.status === 200) {
                vm.entitiesDetails = angular.copy(res.data.data);
                vm.entitiesDetails.push(res.data.total);
            }
        });
    }

    function disableStripeConnectPlatformAgreement(entity) {
        if (entity.accepted_stripe_terms) {
            vm.spinner.active = true;
            EntityService.acceptStripeTerms(entity.id).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    ModalService.alert('You successfully accepted Stripe terms and conditions for entity ' + entity.business_name, 'Stripe Terms and conditions');
                }
            });
        } else {
            entity.accepted_stripe_terms = !entity.accepted_stripe_terms;
        }
    }

    function searchInputEntity() {
        vm.entitiesName = vm.allEntitiesList.filter(function (value) {
            return value.name.toLowerCase().match(vm.searchEntity.toLowerCase());
        });
    }

    function goTo(path) {
        $location.path(path);
    }
}
