import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { FormProvider, useForm } from 'react-hook-form';

import classes from './SelectCoachStep.module.scss';
import { PageTitle } from '../../../../../shared/components/ui-components';
import { ButtonRow } from '../../../../components/customer-self-setup-components';
import { UseStepperReturn } from '../../../../../shared/hooks/useStepper';
import { coachStore } from '../../../../stores/customerSelfSetupStores/coachStore';
import { CoachListFilterFormValues } from '../../../../interfaces/coachList/CoachListFilters';
import { CoachTable } from './CoachTable/CoachTable';
import { useAutoSubmitForm } from '../../../../../shared/hooks/useAutoSubmitForm';
import { useScrollToTop } from '../../../../../shared/hooks/useScrollToTop';
import { useUpsertCustomer } from '../../../../hooks/customerSelfSetupHooks/useUpsertCustomer';

type SelectCoachStepProps = {
  stepperMethods: UseStepperReturn;
  entityId: number;
};

const SelectCoachStep = observer(({ stepperMethods, entityId }: SelectCoachStepProps) => {
  useScrollToTop();

  const { selectedCoach, filterMethods } = coachStore;

  const formMethods = useForm<CoachListFilterFormValues>({
    defaultValues: filterMethods.filterData,
  });

  const { handleSubmit, watch, reset } = formMethods;

  const { totalElements, applyFilters } = filterMethods;

  const { upsertCustomer } = useUpsertCustomer();

  useAutoSubmitForm(handleSubmit(applyFilters), watch, ['coachName']);

  useEffect(() => {
    // auto submit form on mount and get coaches, useAutoSubmitForm
    reset({ entityId });
  }, [entityId]);

  const handleNextStep = async () => {
    await upsertCustomer();

    stepperMethods.goToNextStep();
  };

  return (
    <FormProvider {...formMethods}>
      <PageTitle
        className={classes.title}
        title={`Coach list (${totalElements || 0})`}
        customTopMargin
      />
      <CoachTable />
      <ButtonRow
        yesHandler={handleNextStep}
        yesDisabled={!selectedCoach}
        noHandler={stepperMethods.goToPreviousStep}
      />
    </FormProvider>
  );
});

export { SelectCoachStep };
