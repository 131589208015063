const autoNgTemplateLoaderTemplate1 = require('./change-password-form.template.html');

(function () {
  'use strict';

  ChangePasswordFormController.$inject = ['CoachService', '$routeParams', 'GeneralService'];

  function ChangePasswordFormController(CoachService, $routeParams, GeneralService) {
    const vm = this;
    vm.spinner = false;
    vm.customerPasswords = {
      password: '',
      repeatPassword: ''
    };
    vm.customerPasswordParams = {};

    vm.checkPassword = (form, password, repeatPassword) => {
      GeneralService.checkPassword(form, password, repeatPassword, vm.customerPasswords);
    };

    vm.updateUserPassword = async (form) => {
      const resp = await CoachService.updateUserPassword($routeParams.id, vm.customerPasswords, vm.customerPasswordParams);
      if (resp.status === 200) {
        vm.onPasswordChanged(form);
      }
    };

    vm.onPasswordChanged = (form) => {
      vm.customerPasswords = {
        password: '',
        repeatPassword: '',
      };
      vm.customerPasswordParams = {};
      form.$setPristine();
      form.$setUntouched();
      vm.processOnSuccessBinding();
    };

    vm.processOnSuccessBinding = () => {
      if (typeof vm.onSuccess  === 'function') {
        vm.onSuccess();
      }
    };
  }

  const releasesModule = angular.module('app');
  releasesModule.component('changePasswordForm', {
    templateUrl: autoNgTemplateLoaderTemplate1,
    controller: ChangePasswordFormController,
    controllerAs: 'vm',
    bindings: {
      onSuccess: '<',
    },
  });
})();
