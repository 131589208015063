(function () {
    'use strict';

    angular.module('app.salesDeposits')
        .controller('SalesDepositsController', SalesDepositsController);

    SalesDepositsController.$inject = [
        '$location',
        '$routeParams',
        'utilService',
        'SalesService'
    ];

    function SalesDepositsController($location, $routeParams, utilService, SalesService) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.prevPage = null;
        vm.nextPage = null;

        vm.selectedEntityTab = {
            id: vm.loggedInUser.id,
            business_name: vm.loggedInUser.gymName
        };

        if (vm.loggedInUser.role === 'SUPERUSER') {
            vm.selectedEntityTab = JSON.parse(localStorage.getItem('gym'));
        }

        vm.goTo = goTo;
        vm.getDeposits = getDeposits;

        vm.getDeposits(null, null);

        function goTo(page) {
            $location.path(page);
        }

        function getDeposits(prev, next) {
            SalesService.getDeposits({
                prev_page: prev,
                next_page: next,
                from: $routeParams.from,
                to: $routeParams.to,
                limit: 20,
                entity_id: vm.loggedInUser.role === 'SUPERUSER' ? vm.selectedEntityTab.id : undefined,
            }).then(function (res) {
                if (res.status === 200) {
                    vm.deposits = res.data.deposits;
                    vm.prevPage = res.data.prev_page;
                    vm.nextPage = vm.deposits.length < 20 ? null : res.data.next_page;
                }
            });
        }
    }
})();
