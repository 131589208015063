'use strict';

export const paymentModule = angular.module(
  'app.payment',
  []
).config(paymentConfig);

paymentConfig.$inject = ['$routeProvider'];

function paymentConfig($routeProvider) {
  $routeProvider
    .when('/checkout', {
      template: '<ngz-checkout></ngz-checkout>',
    })
    .when('/payment-result', {
      template: '<ngz-payment-result></ngz-payment-result>',
    })
    .when('/select-subscription', {
      template: '<ngz-select-subscription></ngz-select-subscription>',
    });
}