import { getService } from 'react-in-angularjs';

export const goToWithParams = (
  path: string,
  params: Record<string, any>,
  replaceHistory: boolean = false,
) => {
  const $location = getService('$location');
  const $rootScope = getService('$rootScope');

  if (replaceHistory) {
    $location.path(path).search(params).replace();
  } else {
    $location.path(path).search(params);
  }

  $rootScope.$apply();
};
