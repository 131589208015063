import React from 'react';

import { Control } from 'react-hook-form';

import {
  InputBox,
  LbAutocomplete,
  LbInput,
} from '../../../../../../shared/components/form-components';
import { UserProfileFromValue } from '../UserProfileStep.settings';
import { states } from '../../../../../../shared/constants/states';
import { validateOnlyInteger } from '../../../../../../shared/utils/reactHookFormUtils';

type AddressDataFromProps = {
  control: Control<UserProfileFromValue>;
};

const AddressDataFrom = ({ control }: AddressDataFromProps) => {
  const generalInputProps = {
    control,
    size: 'small',
    variant: 'standard',
  } as const;

  return (
    <InputBox title="Address">
      <LbInput
        {...generalInputProps}
        name="customer.address1"
        label="Address"
        rules={{ required: 'Address is required' }}
      />
      <LbInput
        {...generalInputProps}
        name="customer.city"
        label="City"
        rules={{ required: 'City is required' }}
      />
      <LbAutocomplete
        {...generalInputProps}
        name="customer.state"
        label="State"
        options={states}
        rules={{ required: 'State is required' }}
      />
      <LbInput
        {...generalInputProps}
        name="customer.zipCode"
        label="Zip Code"
        rules={{ required: 'Zip Code is required', validate: { validateOnlyInteger } }}
      />
    </InputBox>
  );
};

export { AddressDataFrom };
