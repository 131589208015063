const autoNgTemplateLoaderTemplate1 = require('./reset-password.template.html');

'use strict';

(function () {
    angular
        .module('app.resetPassword', [])
        .config(resetPasswordConfig);

    resetPasswordConfig.$inject = ['$routeProvider'];

    function resetPasswordConfig($routeProvider) {
        $routeProvider
            .when('/reset-password', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'ResetPasswordController',
                controllerAs: 'vm',
                resolve: {
                    skipIfLoggedIn: ['AuthService', function (AuthService) {
                        return AuthService.skipIfLoggedIn();
                    }]
                }
            })
    }
})();
