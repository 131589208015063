import React, { useState } from 'react';

import { Button, Divider } from '@mui/material';
import { useToggle } from 'react-use';
import { observer } from 'mobx-react';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import classes from './PaymentDetailsWidget.module.scss';
import { InfoBox } from '../../../../../shared/components/ui-components';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../shared/components/table-components';
import { UpdateCardModal } from '../UpdateCardModal/UpdateCardModal';
import { currentSubscriptionPlanStore } from '../../../../stores/currentSubscriptionPlanStore';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';
import { localDataFormat } from '../../../../../shared/constants/apiDateFormat';
import { InfoCell } from '../components';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';
import { getHumanizeBillingPeriodType } from '../../../../utils/getHumanizeBillingPeriodType/getHumanizeBillingPeriodType';

type PaymentDetailsWidgetProps = {
  customerId: number;
  entityId: number;
};
// TODO refactor, crete separate components
const PaymentDetailsWidget = observer(
  ({ customerId, entityId }: PaymentDetailsWidgetProps) => {
    const [isShowUpdateCardModal, toggleShowUpdateCardModal] = useToggle(false);
    const [isAlreadyRetry, setIsAlreadyRetry] = useState(false);
    const { currentSubscriptionPlan, paymentStatusFlag, retryPayment } =
      currentSubscriptionPlanStore;

    if (!currentSubscriptionPlan) {
      return null;
    }
    const { lastTransaction, explorePlan } = currentSubscriptionPlan;
    const { billingInfo } = lastTransaction;

    const handleRetryPayment = async () => {
      await retryPayment();
      setIsAlreadyRetry(true);
    };

    const formattedNextBillingDate = getFormattedDate(
      billingInfo.nextBillingDate,
      localDataFormat,
    );

    const formattedActiveSinceDate = getFormattedDate(
      billingInfo.activeSinceDate,
      localDataFormat,
    );

    return (
      <>
        <InfoBox title="Payment Details" customContentPadding>
          <div className={classes.paymentDetailsTableView}>
            <GridTableRow variant="unstyled" className={classes.billInfoRow}>
              <GridTableCell variant="title">Billing Info</GridTableCell>
              <GridTableCell>
                <p>{billingInfo.customerName}</p>
                <p>{billingInfo.billingEmail}</p>
              </GridTableCell>
            </GridTableRow>
            <Divider className={classes.divider} />
            <GridTableRow variant="unstyled" className={classes.billingDatesRow}>
              <GridTableCell>
                <InfoCell title="Billing Period">
                  {!paymentStatusFlag.CANCELED && (
                    <TitleWithDescription
                      title={
                        billingInfo.billingPeriodType &&
                        getHumanizeBillingPeriodType(billingInfo.billingPeriodType)
                      }
                      description={
                        billingInfo.nextBillingDate &&
                        `Next billing date: ${formattedNextBillingDate}`
                      }
                      titleFont="inherit"
                      direction="row"
                    />
                  )}
                  {paymentStatusFlag.CANCELED && explorePlan && (
                    <div>
                      {getHumanizeBillingPeriodType(explorePlan.billingPeriodType)}
                    </div>
                  )}
                </InfoCell>
              </GridTableCell>
              <GridTableCell>
                <InfoCell title="Active Since">
                  {billingInfo.activeSinceDate && <div>{formattedActiveSinceDate}</div>}
                </InfoCell>
              </GridTableCell>
            </GridTableRow>
            <div className={classes.actionRow}>
              <Button variant="outlined" size="small" onClick={toggleShowUpdateCardModal}>
                Update Card
              </Button>
              {paymentStatusFlag.PAYMENT_ISSUE && (
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={
                    isAlreadyRetry ? <CheckRoundedIcon /> : <RefreshRoundedIcon />
                  }
                  onClick={handleRetryPayment}
                  disabled={isAlreadyRetry}
                >
                  Retry payment
                </Button>
              )}
            </div>
          </div>
        </InfoBox>
        {isShowUpdateCardModal && (
          <UpdateCardModal
            customerId={customerId}
            entityId={entityId}
            onClose={toggleShowUpdateCardModal}
          />
        )}
      </>
    );
  },
);

export { PaymentDetailsWidget };
