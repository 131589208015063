import { UseControllerProps } from 'react-hook-form';
import { patterns } from '../../../constants/patterns';

type GetPasswordRules = {
  minLength: number;
  maxLength: number;
  rules: any;
};

export const getPasswordRules = ({ minLength, maxLength, rules }: GetPasswordRules) => {
  const passwordRules: UseControllerProps['rules'] = {
    required: 'Password is required',
    pattern: {
      value: patterns.ONLY_WORD_CHARACTERS,
      message: 'Password should not contain any special characters, symbols or spaces',
    },
    minLength: {
      value: minLength,
      message: `Password must be at least ${minLength} characters`,
    },
    maxLength: {
      value: maxLength,
      message: `Password must be less than ${maxLength} characters`,
    },
  };

  return { ...passwordRules, ...rules };
};
