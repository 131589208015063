import React from 'react';

import { useAsyncFn } from 'react-use';

import classes from './ChangePasswordModal.module.scss';
import { LbModal } from '../../../../../../../../shared/components/modals';
import { PasswordInput } from '../../../../../../../../shared/components/form-components';
import { useChangePasswordForm } from './hooks/useChangePasswordForm';

type ChangePasswordModalProps = {
  userId: number;
  onClose: () => void;
  isChangeCurrentUserPassword: boolean;
};

const ChangePasswordModal = ({
  userId,
  onClose,
  isChangeCurrentUserPassword,
}: ChangePasswordModalProps) => {
  const { control, handlerPasswordChange, validateConfirmPassword } =
    useChangePasswordForm({
      userId,
      isChangeCurrentUserPassword,
      onSuccessChange: onClose,
    });

  const [status, handleFormSubmit] = useAsyncFn(handlerPasswordChange);

  const generalInputProps = { control, size: 'small', variant: 'outlined' } as const;

  return (
    <LbModal
      open
      title="Change Password"
      primaryBtnConfig={{ text: 'Save', onClick: handleFormSubmit }}
      secondaryBtnConfig={{ text: 'Cancel', onClick: onClose }}
      isLoading={status.loading}
    >
      <form noValidate className={classes.formBox}>
        {isChangeCurrentUserPassword && (
          <PasswordInput
            {...generalInputProps}
            label="Current Password"
            name="currentPassword"
            rules={{
              required: 'Current password is required',
            }}
          />
        )}
        <PasswordInput
          {...generalInputProps}
          label="New Password"
          name="password"
          rules={{
            required: 'Password is required',
          }}
        />
        <PasswordInput
          {...generalInputProps}
          label="Confirm New Password"
          name="repeatPassword"
          rules={{
            required: 'Confirm password is required',
            validate: validateConfirmPassword,
          }}
        />
      </form>
    </LbModal>
  );
};

export { ChangePasswordModal };
