(function () {
  'use strict';

  angular.module('app').controller('EntityBusinessInfoController', EntityBusinessInfoController);

  EntityBusinessInfoController.$inject = ['$scope', 'EntityService', 'constants', 'CoachService',
    '$routeParams', '$window', '$location', 'ModalService'];

  function EntityBusinessInfoController($scope, EntityService, constants, CoachService, $routeParams, $window,
                                        $location, ModalService) {
    const vm = this;
    vm.entity = $scope.createEntity;
    vm.entityId = $scope.entityId;
    vm.spinner = $scope.spinner;
    vm.businessInfo = {};
    vm.states = constants.states;
    vm.entityTypeOptions = constants.entityTypeOptions;
    vm.ENTITY_TYPES = constants.enums.entityTypes;
    vm.corporationTypes = constants.corporationTypes;
    vm.businessTypes = constants.businessTypes;
    vm.isStripeConnected = !!$routeParams.isStripeConnected;
    vm.redirectingToStripe = false;
    vm.enums = constants.enums;

    vm.initOnEntitySetup = initOnEntitySetup;
    vm.getBusinessInfo = getBusinessInfo;
    vm.getParentEntities = getParentEntities;
    vm.onEntityTypeChange = onEntityTypeChange;
    vm.checkEmailUnique = checkEmailUnique;
    vm.checkZipCode = checkZipCode;
    vm.createEntity = createEntity;
    vm.connectStripe = connectStripe;
    vm.onNext = onNext;
    vm.goToNextStep = goToNextStep;
    vm.save = save;

    window.onbeforeunload = function () {
      if ($location.path() === '/entity-setup' && !vm.redirectingToStripe) {
        return "Are you sure you want to leave this page?";
      }
    };

    vm.$onInit = () => {
      if ($location.url().includes('entity-profile')) {
        vm.isEntityProfile = true;
        vm.getBusinessInfo();
      } else {
        vm.initOnEntitySetup();
      }
    }

    function initOnEntitySetup() {
      if (vm.isStripeConnected) {
        vm.entityId = $routeParams.entity_id;
        vm.getBusinessInfo().then(() => {
          vm.businessInfo.isSubscriptionOn = true;
        });
      }
      vm.getParentEntities();
    }

    function getBusinessInfo() {
      return new Promise(resolve => {
        vm.spinner.active = true;
        EntityService.getBusinessDetails(vm.entityId)
          .then(res => {
            if (res.status === 200) {
              vm.businessInfo = res.data;
              resolve(true);
            }
          })
          .finally(() => vm.spinner.active = false);
      });
    }

    function getParentEntities() {
      EntityService.getParentEntities().then(res => {
        if (res.status === 200) {
          vm.parentEntities = res.data;
        }
      });
    }

    function onEntityTypeChange() {
      const entityTypesWithDefaultOffSubscription = [vm.ENTITY_TYPES.SCHOLASTIC];
      const isSubscriptionDefaultOff = entityTypesWithDefaultOffSubscription.includes(vm.businessInfo.entityType);

      vm.isChildEntity = vm.businessInfo.entityType === vm.ENTITY_TYPES.SCHOOL;

      vm.businessInfo.isSubscriptionOn = !vm.isChildEntity && !isSubscriptionDefaultOff;
    }

    function checkEmailUnique(form) {
      if (vm.businessInfo.userEmail && !vm.isEntityProfile) {
        CoachService.checkUserEmail(vm.businessInfo.userEmail).then(function (res) {
          form["userEmail"].$setValidity("duplicate", res.status === 404);
        });
      }
    }

    function checkZipCode() {
      return new Promise((resolve, reject) => {
        EntityService.checkZipCode({
          city: vm.businessInfo.city,
          address: vm.businessInfo.address,
          zip: vm.businessInfo.zip,
          state: vm.businessInfo.state
        }).then(res => {
          if (res.status === 200) {
            resolve(true);
          }
        }).finally(() => {
          reject(false);
        });
      });
    }

    function createEntity() {
      return new Promise(resolve => {
        vm.spinner.active = true;
        vm.checkZipCode()
          .then(() => {
            if (!vm.isChildEntity) {
              return EntityService.createEntity(vm.businessInfo);
            } else {
              return EntityService.createSchool(vm.businessInfo);
            }
          })
          .then(res => {
            if (res.status === 200) {
              vm.spinner.active = false;
              vm.entityId = res.data.entityID;
              resolve(true);
            }
          })
          .finally(() => vm.spinner.active = false);
      });
    }

    function connectStripe() {
      vm.spinner.active = true;
      vm.createEntity()
        .then(() => EntityService.getStripeLink({entityID: vm.entityId}))
        .then(res => {
          if (res.status === 200) {
            vm.redirectingToStripe = true;
            $window.location.href = res.data.stripeUrl;
          }
        })
        .finally(() => vm.spinner.active = false);
    }

    function onNext() {
      if (vm.entityId) {
        vm.save().then(() => vm.goToNextStep());
      } else {
        vm.createEntity().then(() => vm.goToNextStep());
      }
    }

    function goToNextStep() {
      vm.entity = Object.assign(vm.entity, vm.businessInfo);
      vm.entity.entityID = vm.entityId;
      $scope.next();
      $scope.$apply();
    }

    function save() {
      return new Promise(resolve => {
        vm.spinner.active = true;
        EntityService.editBusinessDetails(vm.entityId, vm.businessInfo)
          .then(res => {
            if (res.status === 200) {
              resolve(true);
              vm.isEntityProfile && ModalService.savedChanges();
            }
          })
          .finally(() => vm.spinner.active = false);
      });
    }
  }

})();
