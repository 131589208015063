'use strict';

angular.module('app.revenueReport').controller('RevenueReportController', RevenueReportController);

RevenueReportController.$inject = ['$rootScope', '$scope', '$location', 'utilService', 'StatsService'];

function RevenueReportController($rootScope, $scope, $location, utilService, StatsService) {
    var vm = this;
    vm.spinner = {active: false};
    vm.revenueReportTab = ['Month to Date', 'Year to Date'];
    vm.selectedRevenueReportTab = vm.revenueReportTab[0];
    vm.endDate = new Date();
    vm.startDate = new Date(vm.endDate.getFullYear(), vm.endDate.getMonth(), 1);
    vm.startIsOpen = false;
    vm.endIsOpen = false;
    vm.allEntities = [];
    vm.pagination = {};
    vm.prms = {
        entity: '',
        sortField: 'gross',
        sort: 'desc',
        skip: 0,
        limit: 10
    };

    vm.filterEntities = filterEntities;
    vm.setSortFilter = setSortFilter;
    vm.isActiveSort = isActiveSort;
    vm.formatDate = formatDate;
    vm.goTo = goTo;
    vm.closeDatePicker = closeDatePicker;

    vm.filterEntities(0);

    function closeDatePicker(dateVariable) {
        $scope.$evalAsync(function () {
            vm[dateVariable + 'IsOpen'] = false;
        });
    }

    function filterEntities(skip) {
        if ($location.path() === '/superuser-dashboard') {
            vm.spinner.active = true;
            vm.startDate = vm.selectedRevenueReportTab === 'Month to Date' ?
                new Date(vm.endDate.getFullYear(), vm.endDate.getMonth(), 1) :
                new Date(vm.endDate.getFullYear(), 0, 1);
        }

        vm.prms.startDate = utilService.formatDate(vm.startDate, 'MM-DD-YYYY');
        vm.prms.endDate = utilService.formatDate(vm.endDate, 'MM-DD-YYYY');
        vm.prms.skip = skip;

        StatsService.getRevenueReport(vm.prms).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                if (res.data.length === 0 && vm.prms.skip > 0) {
                    $rootScope.$broadcast('goBack');
                } else {
                    vm.allEntities = res.data;
                    vm.pagination.reset(vm.allEntities.length, vm.prms.skip);
                }
            }
        });
    }

    function setSortFilter(sort, field) {
        vm.prms.sort = sort;
        vm.prms.sortField = field;
        vm.filterEntities(0);
    }

    function isActiveSort(order, field) {
        return vm.prms.sort === order && vm.prms.sortField === field;
    }

    function formatDate(date, template) {
        return utilService.formatDate(date, template);
    }

    function goTo(path) {
        $location.path(path);
    }
}
