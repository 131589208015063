import React from 'react';

import { angularize } from 'react-in-angularjs';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Button, ButtonProps } from '@mui/material';

import { LbMenu } from '../../../shared/components/menu-components';
import { MenuOptions } from '../../../general-types';
import { SystemProviders } from '../../../shared/providers';
import { FLAGS, useFlag } from '../../../shared/clients/unleash';

type AddEntityMenuProps = Pick<ButtonProps, 'variant' | 'size'>;

const menuOptions: MenuOptions[] = [
  {
    id: 1,
    label: 'New Flow 2024',
    href: '#!/entity-setup-flow-2024',
  },
  {
    id: 2,
    label: 'New Flow',
    href: '#!/entity-setup',
  },
];

const AddEntityMenu = ({ size, variant = 'contained' }: AddEntityMenuProps) => {
  const isShowNewFlow2024 = useFlag(FLAGS.SHOW_NEW_FLOW_2024);

  return isShowNewFlow2024 ? (
    <LbMenu
      buttonProps={{
        endIcon: <KeyboardArrowDownRoundedIcon />,
        size,
        variant,
      }}
      options={menuOptions}
    >
      Add a New Entity
    </LbMenu>
  ) : (
    <Button href="#!/entity-setup" variant={variant} size={size}>
      Add a New Entity
    </Button>
  );
};

// exclude SystemProviders in SystemProviders, not use in react components
const NgzAddEntityMenu = (props: AddEntityMenuProps) => {
  return (
    <SystemProviders>
      <AddEntityMenu {...props} />
    </SystemProviders>
  );
};

angularize(NgzAddEntityMenu, 'ngzAddEntityMenu', angular.module('app.entityManagement'), {
  size: '=',
  variant: '=',
});

export { NgzAddEntityMenu, AddEntityMenu };
