import React from 'react';
import PropTypes from 'prop-types';

import classes from './EmptyListPlaceholder.module.scss';

const EmptyListPlaceholder = ({ text }) => {
  return (
    <figure className={classes.box}>
      <figcaption>{text}</figcaption>
    </figure>
  );
};

EmptyListPlaceholder.propTypes = {
  text: PropTypes.string,
};

export { EmptyListPlaceholder };
