import React from 'react';
import PropTypes from 'prop-types';

import { Button, Switch } from '@mui/material';
import moment from 'moment';

import classes from './DataRangeFilter.module.scss';
import {
  DateRangePicker,
  LbFormControlLabel,
} from '../../../../../../shared/components/form-components';
import { SeparatorLine } from '../../../../../../shared/components/ui-components';
import { FLAGS, useFlag } from '../../../../../../shared/clients/unleash';

const DataRangeFilter = ({
  control,
  onSubmit,
  isShowConsumptionWidget,
  toggleShowConsumptionWidget,
  customerRegistrationDate,
}) => {
  const isShowConsumption = useFlag(FLAGS.SHOW_CONSUMPTION_WIDGET);

  return (
    <form className={classes.filterBox}>
      {isShowConsumption && (
        <>
          <LbFormControlLabel
            control={
              <Switch
                checked={isShowConsumptionWidget}
                onChange={toggleShowConsumptionWidget}
              />
            }
            label="Show Consumption:"
            labelPlacement="start"
            data-test-id="show-consumption-switch"
          />
          <SeparatorLine />
        </>
      )}
      <DateRangePicker
        control={control}
        size="small"
        variant="outlined"
        startPickerProps={{
          name: 'startDate',
          label: 'Start Date',
          minDate: moment(customerRegistrationDate),
          rules: { required: 'Start date is required' },
        }}
        endPickerProps={{
          name: 'endDate',
          label: 'End Date',
          disableFuture: true,
          rules: { required: 'End date is required' },
        }}
        dataTestId="date-range-picker"
      />
      <Button size="small" variant="outlined" type="submit" onClick={onSubmit}>
        Apply
      </Button>
    </form>
  );
};
DataRangeFilter.propTypes = {
  control: PropTypes.object,
  onSubmit: PropTypes.func,
  isShowConsumptionWidget: PropTypes.bool,
  toggleShowConsumptionWidget: PropTypes.func,
  customerRegistrationDate: PropTypes.string,
};

export { DataRangeFilter };
